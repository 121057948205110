import React, {useEffect, useState} from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PageHeading from "../partials/PageHeading";
import {useDispatch, useSelector} from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {createHelpTopic, updateHelpTopic} from "../../store/actions/helpTopicActions";
import {useHistory} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import Card from "react-bootstrap/Card";
import Footer from "../partials/Footer";


const HelpTopicForm = (props) => {

    const history = useHistory();

    const [showCardModal, setShowCardModal] = useState(false);
    const [cardModalBody, setCardModalBody] = useState('');

    const cardModalVariants = {
        show: {
            x: 0,
            transition: {duration: 0.5}
        }
    }

    const showMessage = ((body) => {
        setCardModalBody(body);
        setShowCardModal(true);
        setTimeout(() => {
            setShowCardModal(false)
        }, 1000);
    });

    const editorConfiguration = {
        ckfinder: {uploadUrl: '/api/upload/photo', options: {resourceType: 'Images'}},
        toolbar: ['heading', 'bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'insertTable', 'imageUpload', 'blockQuote', 'mediaEmbed', '|', 'undo', 'redo']
    };

    const [helpTopicId, setHelpTopicId] = useState('');
    const [category, setCategory] = useState('therapist');
    const [header, setHeader] = useState('');
    const [bodyText, setBodyText] = useState('');
    const [isDirty, setIsDirty] = useState(false);

/*
    console.log('history', history);
    console.log('match', props.match);
    console.log('computedMatch', props.computedMatch);
    console.log('computedMatch.params', props.computedMatch.params);
    console.log('location', props.location);
    console.log('props', props);
*/
    const helpTopicParamId = props.computedMatch.params.id;
    //console.log('helpTopicParamId',helpTopicParamId);

    const helpTopics = useSelector((state) => state.helpTopics);
    //console.log('helpTopics', helpTopics);

    let helpTopic = helpTopics.find((helpTopic) => helpTopic._id === helpTopicParamId);
    //console.log('typeof helpTopic',typeof helpTopic);

    // Load the helpTopic in the state
    useEffect(() => {
        if (typeof helpTopic !== 'undefined') {
            // console.log('Loading helpTopic in state');
            setHelpTopicId(helpTopic._id);
            setCategory(helpTopic.category);
            setHeader(helpTopic.header);
            setBodyText(helpTopic.bodyText);
        }
    }, []);

    //console.log('helpTopic',helpTopic);
    //console.log('Values',helpTopicId,category,header,bodyText);

    const handleBack = (e) => {
        e.preventDefault();
        //console.log('handleBack');
        // Back to admin page (No save)
        if (isDirty) {
            //console.log("isDirty");
            if (window.confirm("Are you sure you want to go back? You will loose your changes.") !== true) {
                return;
            }
        }
        history.push(`/admin`);
    }

    const handleReset = (e) => {
        e.preventDefault();
        //console.log('handleReset');
        // Revert back to before edit
        if (helpTopicId === '') {
            // setCategory('therapist'); // We can leave category as is. No need to change back
            setHeader('');
            setBodyText('');
        } else {
            setCategory(helpTopic.category);
            setHeader(helpTopic.header);
            setBodyText(helpTopic.bodyText);
        }
        setIsDirty(false);
        showMessage('Help topic reset');
    }

    const dispatch = useDispatch();

    const handleSave = (e) => {
        e.preventDefault();
        //console.log('handleSave');
        setIsDirty(false);
        if (helpTopicId === '')
            dispatch(createHelpTopic({category: category, header: header, bodyText: bodyText}));
        else
            dispatch(updateHelpTopic({_id: helpTopicId, category: category, header: header, bodyText: bodyText}));
        showMessage('Help topic saved');
    }

    const handleCategoryChange = (category) => {
        //console.log('categoryChange',category);
        setCategory(category);
        setIsDirty(true);
    }

    const handleHeaderChange = (e) => {
        //console.log('headerChange',e.target.value);
        setHeader(e.target.value);
        setIsDirty(true);
    }

    const handleBodyTextChange = (e, editor) => {
        //console.log('bodyTextChange', editor.getData());
        const data = editor.getData();
        setBodyText(data);
        setIsDirty(true);
        //console.log( data );
    }

    return (
        <div className="container">
            <PageHeading title='EMDR Bilateral Stimulator'/>
            {(helpTopicId === '') &&
            <h3>New Helptopic</h3>
            }
            {(helpTopicId !== '') &&
            <h3>Edit helptopic</h3>
            }

            <Button as="input" type="button" value="Back" onClick={handleBack}/>
            <Form>
                <Form.Group controlId="category">
                    <Form.Label className="mr-3">Help topic for:</Form.Label>
                    &nbsp;
                    <Form.Check inline type="radio" name="category" label="Therapist"
                                checked={(category === 'therapist')}
                                onChange={((category) => handleCategoryChange('therapist'))}/>
                    <Form.Check inline type="radio" name="category" label="Client" checked={(category === 'client')}
                                onChange={((category) => handleCategoryChange('client'))}/>
                </Form.Group>
                <Form.Group controlId="header">
                    <Form.Control size="lg" type="text" placeholder="Enter help topic title" value={header}
                                  onChange={handleHeaderChange}/>
                </Form.Group>
            </Form>
            <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={bodyText}
                onChange={(event, editor) => {
                    handleBodyTextChange(event, editor)
                }}
            />
            <div className="mt-3">
                <Button className="mr-3" as="input" type="submit" value="Save" onClick={handleSave}/>
                <Button as="input" type="reset" value="Reset" onClick={handleReset}/>
            </div>

            <AnimatePresence>
                {showCardModal &&
                <motion.div
                    initial={{x: 200}}
                    variants={cardModalVariants}
                    animate="show"
                    exit={{opacity: 0, transition: {duration: 1}}}
                    style={{position: "fixed", zIndex: 10, bottom: "80px", right: "40px"}}>
                    <Card className="" onClose={() => setShowCardModal(false)}>
                        <Card.Body>{cardModalBody}</Card.Body>
                    </Card>
                </motion.div>
                }
            </AnimatePresence>
            <Footer/>
        </div>

    )
}

export default HelpTopicForm
