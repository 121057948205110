import React, {useState} from 'react';
import AuthService from '../../services/AuthService';
import Message from '../partials/Message';
import PageHeading from "../partials/PageHeading";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

const debug = false;

const Register = (props) => {
    const [user, setUser] = useState({email: "", password: "", passwordConfirm: "", firstname: "", lastname: ""});
    const [message, setMessage] = useState(null);

    const onChange = e => {
        setMessage(null); // Clear error message
        setUser({...user, [e.target.name]: e.target.value});
    }

    const resetForm = () => {
        setUser({email: "", password: "", passwordConfirm: "", firstname: "", lastname: ""});
    }

    const onSubmit = e => {
        e.preventDefault();
        let validationErrors = [];
        // Verify if all fields are filled in
        if (user.email === '') validationErrors.push('Email is mandatory');
        if (user.firstname === '') validationErrors.push('First name is mandatory');
        if (user.lastname === '') validationErrors.push('Last name is mandatory');
        if (user.password === '') validationErrors.push('Password is mandatory');
        // Verify if passwords are the same
        if (user.password !== user.passwordConfirm) validationErrors.push('Passwords don\'t match');
        if (validationErrors.length > 0) {
            setMessage( {msgBody: validationErrors.join('<br/>'), msgError: true});
            return;
        }
        AuthService.register(user).then(data => {
            if (debug) console.log('data',data);
            const {message} = data;
            if (debug) console.log('message', message);
            setMessage(message);
            // Redirect user to login page if there is no error message (i.e. successful registration)
            if (!message.msgError) {
                resetForm();
                props.history.push('/therapist/login');
            }
        });
    }

    const [passwordType, setPasswordType] = useState('password');
    const [passwordConfirmType, setPasswordConfirmType] = useState('password');

    const togglePassword = (field) => {
        if (field === 'password') setPasswordType(passwordType === 'password' ? 'text' : 'password');
        if (field === 'passwordConfirm') setPasswordConfirmType(passwordConfirmType === 'password' ? 'text' : 'password');
    }

    return (
        <div className="container">
            <PageHeading title='EMDR Bilateral Stimulator' />
            <Form className="ml-5" onSubmit={onSubmit}>
                <h3 className="mb-3">Please register</h3>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="email">
                            <Form.Control name="email" type="text" placeholder="Enter email" autoComplete="username email" value={user.email} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="firstname">
                            <Form.Control name="firstname" type="text" placeholder="Enter first name" autoComplete="given-name" value={user.firstname} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="lastname">
                            <Form.Control name="lastname" type="text" placeholder="Enter last name" autoComplete="family-name" value={user.lastname} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="password">
                            <Form.Control name="password" type={passwordType} placeholder="Enter password" autoComplete="current-password" value={user.password} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        {passwordType === 'password' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEye} onClick={(() => togglePassword("password"))}/>
                        }
                        {passwordType === 'text' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEyeSlash} onClick={(() => togglePassword("password"))}/>
                        }
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="passwordConfirm">
                            <Form.Control name="passwordConfirm" type={passwordConfirmType} placeholder="Confirm password" autoComplete="current-password" value={user.passwordConfirm} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        {passwordConfirmType === 'password' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEye} onClick={(() => togglePassword("passwordConfirm"))}/>
                        }
                        {passwordConfirmType === 'text' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEyeSlash} onClick={(() => togglePassword("passwordConfirm"))}/>
                        }
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Button className="btn btn-primary btn-block mb-3" type="submit">Register</Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        {message ? <Message message={message}/> : null}
                    </Col>
                </Form.Row>
            </Form>
        </div>
    )
}

export default Register;
