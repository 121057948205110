import React from 'react';
import Image from 'react-bootstrap/Image';
import Footer from "./partials/Footer";

const NotFound = () => {
    return (
        <div className="vh-100">
            <div className="h-25 w-100 d-inline-block text-center">
                <h1 className="display-3 mt-5">EMDR Bilateral Stimulator</h1>
                <h3 className="text-secondary">Page not found</h3>
                <Image src="/404.png" alt="#404 - Page not found" fluid/>
            </div>
            <Footer/>
        </div>
    )
}
export default NotFound;
