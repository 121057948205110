import axios from 'axios';

const debug = false;

const url = '/api/clientSession';

export const fetchAllClientSessions = () => {
    if (debug) console.log('fetchAllClientSessions api call')
    return axios.get(`${url}/list`);
};

export const fetchTherapistClientSessions = (id) => {
    if (debug) console.log(`fetchTherapistClientSessions api call for user_id {$id}`)
    return axios.get(`${url}/list/${id}`);
};

export const fetchClientSession = (name) => {
    if (debug) console.log('fetchClientSession api call')
    return axios.get(`${url}/${name}`);
};

export const existsClientSession = (name) => {
    if (debug) console.log('existsClientSession api call')
    return axios.get(`${url}/exists/${name}`);
};

export const createClientSession = (newClientSession) => {
    if (debug) console.log('createClientSession api call');
    return axios.post(url, newClientSession);
}

export const updateClientSession = (updatedClientSession) => {
    if (debug) console.log('updateClientSession api call');
    return axios.put(`${url}/${updatedClientSession.name}`, updatedClientSession);
}

export const deleteClientSession = (id) => {
    if (debug) console.log(`deleteClientSession api call, id: ${id}`);
    return axios.delete(`${url}/${id}`);
}
