import * as api from '../../api/helpTopicsApi';

const debug = false;

export const fetchHelpTopics = () => async (dispatch) => {
    try {
        if (debug) console.log('Start fetch all help topics');
        const {data} = await api.fetchHelpTopics();
        dispatch({ type: 'FETCH_ALL_HELPTOPICS', payload: data });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const createHelpTopic = (helpTopic) => async (dispatch) => {
    try {
        if (debug) console.log('Start create help topic');
        const {data} = await api.createHelpTopic(helpTopic);
        dispatch({ type: 'CREATE_HELPTOPIC', payload: data });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const updateHelpTopic = (helpTopic) => async (dispatch) => {
    try {
        if (debug) console.log('Start update help topic');
        const {data} = await api.updateHelpTopic(helpTopic._id, helpTopic);
        dispatch({ type: 'UPDATE_HELPTOPIC', payload: data });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const deleteHelpTopic = (_id) => async (dispatch) => {
    try {
        if (debug) console.log(`Start delete help topic ${_id}`);
        await api.deleteHelpTopic(_id);
        dispatch({ type: 'DELETE_HELPTOPIC', payload: _id });
    }
    catch (e) {
        console.error(e.message);
    }
}
