import React, {useState, useContext} from 'react';
import AuthService from '../../services/AuthService';
import Message from '../partials/Message';
import {AuthContext} from '../../context/AuthContext';
import PageHeading from "../partials/PageHeading";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

const debug = false;

const Login = props => {

    const authContext = useContext(AuthContext);

    const [user, setUser] = useState({email: "", password: ""});
    const [message, setMessage] = useState(null);
    const [forgotPassword, setForgotPassword] = useState(false);

    const onChange = e => {
        setMessage(null); // Clear error message
        setUser({...user, [e.target.name]: e.target.value});
    }

    const onSubmit = e => {
        e.preventDefault();
        // Prevent the form from being submitted if no email or password is entered
        if (user.email === '' || user.password === '') {
            setMessage({ msgBody: "Please enter your credentials", msgError: true });
            return;
        }
        AuthService.login(user).then(data => {
            const {isAuthenticated, user, sessionExpires, message} = data;
            if (isAuthenticated) {
                authContext.setUser(user);
                authContext.setIsAuthenticated(isAuthenticated);
                authContext.setSessionExpires(sessionExpires);
                props.history.push('/therapist');
            } else
                setMessage(message);
        });
    }

    const handleForgotPassword = e => {
        e.preventDefault();

        // If email address field looks like a valid email address then trigger passwordReset email and set message
        const pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,'i');
        if (pattern.test(user.email)) {
            // Send passwordReset email
            AuthService.forgotPassword(user.email)
                .then(data => {
                    if (debug) console.log('forgotPassword data',data.message);
                    setMessage(data.message);
                    setForgotPassword(false);
                    // Redirect user to homepage after 5 seconds
                    setTimeout(() => {
                        props.history.push('/');
                    }, 5000);
                })
                .catch(err => {
                    console.error('forgotPassword err',err);
                    setMessage( {msgBody: 'Something went wrong. Try again or contact ....', msgError: true});
                    setForgotPassword(true);
                });
            return;
        }

        setForgotPassword(true);
        setMessage(null); // Clear error message
    }

    const handleBackToLogin = e => {
        e.preventDefault();
        setForgotPassword(false);
    }

    const [passwordType, setPasswordType] = useState('password');

    const togglePassword = (field) => {
        if (field === 'password') setPasswordType(passwordType === 'password' ? 'text' : 'password');
    }

    return (
        <div className="container">
            <PageHeading title='EMDR Bilateral Stimulator' />
            <Form className="ml-5" onSubmit={onSubmit}>
                { !forgotPassword &&
                <h3 className="mb-3">Please sign in</h3>
                }
                { forgotPassword &&
                <h3 className="mb-3">Forgot password</h3>
                }

                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="email">
                            <Form.Control name="email" type="text" placeholder="Enter email" autoComplete="username email" value={user.email} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                {!forgotPassword &&
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="password">
                            <Form.Control name="password" type={passwordType} placeholder="Enter password"
                                          autoComplete="current-password" value={user.password} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        {passwordType === 'password' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEye} onClick={(() => togglePassword("password"))}/>
                        }
                        {passwordType === 'text' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEyeSlash} onClick={(() => togglePassword("password"))}/>
                        }
                    </Col>
                </Form.Row>
                }
                <Form.Row>
                    <Col sm="11">
                        {!forgotPassword &&
                        <Button className="btn btn-primary btn-block mb-3" type="submit">Log in</Button>
                        }
                        {forgotPassword &&
                        <Button className="btn btn-primary btn-block mb-3" onClick={handleForgotPassword}>Email reset password link</Button>
                        }
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        {message ? <Message message={message}/> : null}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        {!forgotPassword &&
                        <span onClick={handleForgotPassword} style={{cursor: "pointer"}}>Forgot password?</span>
                        }
                        {forgotPassword &&
                        <span onClick={handleBackToLogin} style={{cursor: "pointer"}}>Back to login form</span>
                        }
                    </Col>
                </Form.Row>


            </Form>

        </div>
    )
}

export default Login;
