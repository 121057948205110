import React, {useEffect, useState} from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton';
import Table from 'react-bootstrap/Table';
import PageHeading from "../partials/PageHeading";
import {useDispatch, useSelector} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import {deleteTherapist, fetchAllTherapists} from "../../store/actions/therapistActions";
import {deleteHelpTopic, fetchHelpTopics} from "../../store/actions/helpTopicActions";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useHistory } from 'react-router-dom';
import Footer from "../partials/Footer";

const debug = false;

const Admin = () => {

    // Buttons
    const [radioValue, setRadioValue] = useState('therapists');

    const radios = [
        { name: 'Therapists', value: 'therapists' },
        { name: 'Help topics', value: 'helpTopics' },
    ];

    const onChange = (e) => {
        setRadioValue(e.currentTarget.value);
    }

    const [radioValue2, setRadioValue2] = useState('therapist');

    const radios2 = [
        { name: 'Therapist', value: 'therapist' },
        { name: 'Client', value: 'client' },
    ];

    const onChange2 = (e) => {
        setRadioValue2(e.currentTarget.value);
    }

    // Dispatch data

    const dispatch = useDispatch();

    useEffect(() => {
        if (debug) console.log('initState');
        dispatch(fetchAllTherapists());
        dispatch(fetchHelpTopics());
    }, []);

    const therapists = useSelector((state) => state.therapists);
    const helpTopics = useSelector((state) => state.helpTopics);

    // Filter helpTopics for category: 'therapist'
    const therapistHelpTopics = helpTopics.filter(helpTopic => {
        return ((helpTopic.category === "therapist" ? helpTopic : null))
    });

    // Filter helpTopics for category: 'client'
    const clientHelpTopics = helpTopics.filter(helpTopic => {
        return ((helpTopic.category === "client" ? helpTopic : null))
    });


    // Therapist handlers
    const handleDeleteTherapist = (e, therapist) => {
        if (debug) console.log(`delete therapist: ${therapist._id}`);
        // TODO: ask for confirmation
        dispatch(deleteTherapist(therapist._id));
        // TODO: Show modal to confirm deletion
    }

    const handleDeleteHelpTopic = (e, helpTopic) => {
        e.stopPropagation();
        if (debug) console.log(`delete ${helpTopic._id}`);
        // TODO: ask for confirmation
        dispatch(deleteHelpTopic(helpTopic._id));
        // TODO: Show modal to confirm deletion
    }

    // Help topic handlers

    const history = useHistory();

    const handleCreateHelpTopic = () => {
        history.push('/admin/helpTopic');
    }

    const handleEditHelpTopic = (e, helpTopic) => {
        e.stopPropagation();
        if (debug) console.log(`edit ${helpTopic._id}`);
        history.push(`/admin/helpTopic/${helpTopic._id}`);
    }

    return (
        <div className="container">

            <PageHeading title='EMDR Bilateral Stimulator - Admin' />

            {/* Therapists / Help topics selector */}
            <div className="container d-flex align-items-center justify-content-center mb-3">
                <ButtonGroup toggle>
                    {radios.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            type="radio"
                            variant={(radioValue === radio.value) ? 'primary' : 'secondary'}
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={onChange}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
            </div>


            {/* Therapists */}
            <div className={(radioValue === 'therapists') ? 'container' : 'hidden'}>
                <Table hover>
                    <thead>
                    <tr className="table-primary">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Last log in</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {therapists.map((therapist, idx) => (

                        <tr key={idx}>
                            <td>{therapist.firstname} {therapist.lastname}</td>
                            <td>{therapist.email}</td>
                            <td>{therapist.role}</td>
                            <td>
                                {new Date(therapist.lastLogin).toLocaleDateString()}
                                &nbsp;
                                {new Date(therapist.lastLogin).toLocaleTimeString()}
                            </td>
                            <td>{therapist.role !== 'admin' &&
                            <FontAwesomeIcon className="mr-3" style={{cursor: "pointer"}} icon={faTrashAlt}
                                             onClick={((e) => handleDeleteTherapist(e, therapist))}/>
                            }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

            {/* Help topics */}
            <div className={(radioValue === 'helpTopics') ? 'container' : 'hidden'}>

                {/* Help topics selector */}
                <span className="mr-3 d-flex justify-content-center">Show help topics for:</span>
                <div className="container d-flex align-items-center justify-content-center mb-3">
                    <ButtonGroup toggle>
                        {radios2.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                type="radio"
                                variant={(radioValue2 === radio.value) ? 'primary' : 'secondary'}
                                name="radio"
                                value={radio.value}
                                checked={radioValue2 === radio.value}
                                onChange={onChange2}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </div>

                <div className="container mb-3">
                    <Button className="mb-3" style={{float: "right"}} onClick={handleCreateHelpTopic}>Add help topic</Button>
                </div>

                <div className={(radioValue2 === 'therapist') ? 'container' : 'hidden'}>
                    <Accordion>
                        {therapistHelpTopics.map((helpTopic) => (
                            <Card className="mb-3" style={{ width: '100%' }} key={helpTopic._id}>
                                <Accordion.Toggle as={Card.Header} eventKey={helpTopic._id}>
                                    {helpTopic.header}
                                    <FontAwesomeIcon className="ml-1" style={{float: "right", cursor: "pointer"}} icon={faTrashAlt} onClick={((e) => handleDeleteHelpTopic(e, helpTopic))}/>
                                    <FontAwesomeIcon className="ml-1" style={{float: "right", cursor: "pointer"}} icon={faPencilAlt} onClick={((e) => handleEditHelpTopic(e, helpTopic))}/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={helpTopic._id}>
                                    <Card.Body dangerouslySetInnerHTML={{ __html: helpTopic.bodyText}}></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                </div>
                <div className={(radioValue2 === 'client') ? 'container' : 'hidden'}>
                    <Accordion>
                        {clientHelpTopics.map((helpTopic) => (
                            <Card className="mb-3" style={{ width: '100%' }} key={helpTopic._id}>
                                <Accordion.Toggle as={Card.Header} eventKey={helpTopic._id}>
                                    {helpTopic.header}
                                    <FontAwesomeIcon className="ml-1" style={{float: "right", cursor: "pointer"}} icon={faTrashAlt} onClick={((e) => handleDeleteHelpTopic(e, helpTopic))}/>
                                    <FontAwesomeIcon className="ml-1" style={{float: "right", cursor: "pointer"}} icon={faPencilAlt} onClick={((e) => handleEditHelpTopic(e, helpTopic))}/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={helpTopic._id}>
                                    <Card.Body dangerouslySetInnerHTML={{ __html: helpTopic.bodyText}}></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                </div>

            </div>


            <div style={{height: '60px'}}/>
            {/* Extra spacer to allow scrolling passed 'fixed bottom' navbar */}

            <Footer/>
        </div>
    )
}
export default Admin;
