import * as api from '../../api/therapistsApi';

const debug = false;

export const fetchAllTherapists = () => async (dispatch) => {
    try {
        if (debug) console.log('Start fetch all therapists');
        const {data} = await api.fetchAllTherapists();
        if (debug) console.log('Awaited fetch all therapists', data);
        dispatch({ type: 'FETCH_ALL_THERAPISTS', payload: data });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const deleteTherapist = (_id) => async (dispatch) => {
    try {
        if (debug) console.log(`Start delete therapist ${_id}`);
        await api.deleteTherapist(_id);
        dispatch({ type: 'DELETE_THERAPIST', payload: _id });
    }
    catch (e) {
        console.error(e.message);
    }
}
