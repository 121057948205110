import React, {useState} from 'react';
import PageHeading from "../partials/PageHeading";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Message from "../partials/Message";
import {checkIfValidClientSession} from "../../store/actions/clientSessionActions";
import Footer from "../partials/Footer";

const Client = (props) => {

    const [sessionName, setSessionName] = useState("");
    const [message, setMessage] = useState(null);

    const onChange = e => {
        setMessage(null); // Clear error message
        setSessionName(e.target.value);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        // Check if valid session
        const isValidClientSession = await checkIfValidClientSession(sessionName);
        if (isValidClientSession)
            props.history.push(`/client/`+sessionName);
        else {
            setMessage({msgBody: 'Not a valid session name. Please check and try again', msgError: true});
        }
    }

    return (
        <div className="container vh-100">

            <PageHeading title='EMDR Bilateral Stimulator' />
            <p>Your therapist should provide you with a session name. Enter the name on the form to start the session.</p>
            <Form className="" onSubmit={onSubmit}>
                <Form.Row>
                    <Col xs={5}>
                        <Form.Group controlId="name">
                            <Form.Label className="sr-only">Session name:</Form.Label>
                            <Form.Control type="text"
                                          name="name"
                                          onChange={onChange}
                                          className="form-control mb-3"
                                          placeholder="example-name"
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="4">
                        <Button className="btn btn-primary mb-3" type="submit">Start session</Button>
                    </Col>
                </Form.Row>
            </Form>
            {message ? <Message message={message}/> : null}
            <Footer/>
        </div>
    )

}

export default Client
