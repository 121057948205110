import * as api from '../../api/clientSessionsApi';

const debug = false;

export const fetchAllClientSessions = () => async (dispatch) => {
    try {
        if (debug) console.log('Start fetch all client sessions');
        const {data} = await api.fetchAllClientSessions();
        if (debug) console.log('Awaited fetch all client sessions', data);
        dispatch({ type: 'FETCH_ALL_CLIENTSESSIONS', payload: data });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const fetchTherapistClientSessions = (_id) => async (dispatch) => {
    try {
        if (debug) console.log('Start fetch therapist client sessions');
        const {data} = await api.fetchTherapistClientSessions(_id);
        if (debug) console.log('Awaited fetch therapist client sessions', data);
        dispatch({ type: 'FETCH_THERAPIST_CLIENTSESSIONS', payload: data });
    }
    catch (e) {
        console.error(e.message);
    }
}


export const fetchClientSession = (name) => async (dispatch) => {
    try {
        if (debug) console.log('Start fetch client session');
        const {data} = await api.fetchClientSession(name);
        if (debug) console.log('Awaited fetch client session', data);
        dispatch({ type: 'FETCH_CLIENTSESSION', payload: data });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const createClientSession = (clientSession) => async (dispatch) => {
    if (debug) console.log('Creating a new client session', clientSession);
    try {
        const {data} = await api.createClientSession(clientSession);
        if (debug) console.log('Awaited create client session', data);
        dispatch({ type: 'CREATE_CLIENTSESSION', payload: data.clientSession });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const existsClientSession = (name) => async (dispatch) => {
    if (debug) console.log('Checking if client session exists', name);
    try {
        const {data} = await api.existsClientSession(name);
        if (debug) console.log('Awaited exists client session', data);
        await dispatch({ type: 'EXISTS_CLIENTSESSION', payload: data });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const checkIfValidClientSession = async (name) => {
    if (debug) console.log('Checking if valid client session', name);
    const {data} = await api.existsClientSession(name);
    return data;
}

// Do not use async as we do not want to block the UI
export const updateClientSession = (clientSession) =>  (dispatch) => {
    try {
        if (debug) console.log('Updating client session', clientSession);
        api.updateClientSession(clientSession).then();
        dispatch({ type: 'UPDATE_CLIENTSESSION', payload: clientSession });
    }
    catch (e) {
        console.error(e.message);
    }
}

export const deleteClientSession = (_id) => async (dispatch) => {
    try {
        if (debug) console.log(`Start delete client session ${_id}`);
        await api.deleteClientSession(_id);
        dispatch({ type: 'DELETE_CLIENTSESSION', payload: _id });
    }
    catch (e) {
        console.error(e.message);
    }
}
