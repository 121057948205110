import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import HelpTopic from './HelpTopic'
import {fetchHelpTopics} from "../../store/actions/helpTopicActions";
import Accordion from "react-bootstrap/Accordion";

const debug = false;

const HelpTopics = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        if (debug) console.log('initState');
        dispatch(fetchHelpTopics());
    }, []);

    const helpTopics = useSelector((state) => state.helpTopics);
    //console.log('helpTopics', helpTopics);
    const category = props.category;

    // Filter helpTopics for category: 'therapist'
    const therapistHelpTopics = helpTopics.filter(helpTopic => {
        return ((helpTopic.category === "therapist" ? helpTopic : null))
    });
    //console.log(therapistHelpTopics);

    // Filter helpTopics for category: 'client'
    const clientHelpTopics = helpTopics.filter(helpTopic => {
        return ((helpTopic.category === "client" ? helpTopic : null))
    });
    //console.log(clientHelpTopics);

    return (
        <div>
            <div className={(category === 'therapist') ? 'container' : 'hidden'}>
                <Accordion>
                    {therapistHelpTopics.map((helpTopic) => (
                        <HelpTopic helpTopic={helpTopic} key={helpTopic._id}/>
                    ))}
                </Accordion>
            </div>
            <div className={(category === 'client') ? 'container' : 'hidden'}>
                <Accordion>
                    {clientHelpTopics.map((helpTopic) => (
                        <HelpTopic helpTopic={helpTopic} key={helpTopic._id}/>
                    ))}
                </Accordion>
            </div>
            <div style={{height: '60px'}}/>
            {/* Extra spacer to allow scrolling passed 'fixed bottom' navbar */}
        </div>
    )
}

export default HelpTopics
