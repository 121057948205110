import { combineReducers } from "redux";
import therapistsReducer from "./therapistReducer";
import helpTopicsReducer from "./helpTopicsReducer";
import clientSessionsReducer from "./clientSessionsReducer";

export default combineReducers({
    therapists : therapistsReducer,
    helpTopics : helpTopicsReducer,
    clientSessions : clientSessionsReducer
})
