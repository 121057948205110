import React, {useEffect, useRef, useState} from 'react';
import { initiateSocket, disconnectSocket, subscribeToClientSession, isConnected as wsIsConnected, sendAction } from './../../services/WebSocketClient';
import PageHeading from "../partials/PageHeading";
import {AnimatePresence, motion, useAnimation} from "framer-motion";
import tinycolor from "tinycolor2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompress, faExpand, faGlobe} from "@fortawesome/free-solid-svg-icons";
import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {existsClientSession} from "../../store/actions/clientSessionActions";
import {useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/Button";
import Footer from "../partials/Footer";

const ClientSession = (props) => {

    const debug = false;
    const debugWs = false;

    const sessionName = props.match.params.name;
    if (debug) console.log('sessionName', sessionName);

    const clientType = 'client';

    const dispatch = useDispatch();

    useEffect(() => {
        if (debug) console.log('initState');
        dispatch(existsClientSession(sessionName));
    }, []);

    const clientSessionExists = useSelector((state) => state.clientSessions.clientSessionExists);

    const handleBack = () => {
        props.history.push('/client');
    }

    const [showCardModal, setShowCardModal] = useState(false);
    const [cardModalBody, setCardModalBody] = useState('');

    const cardModalVariants = {
        show: {
            x: 0,
            transition: {duration: 0.5}
        }
    }

    const showMessage = ((body) => {
        setCardModalBody(body);
        setShowCardModal(true);
        setTimeout(() => {
            setShowCardModal(false)
        }, 1000);
    });

    // Bilateral Stimulation controls
    const [speed, setSpeed] = useState(50);
    const [color, setColor] = useState("#ff5800");
    const [sound, setSound] = useState("No sound");
    const soundRef = useRef();
    soundRef.current = sound;
    const [mode, setMode] = useState("Pursuit");
    const modeRef = useRef();
    modeRef.current = mode;

    // Calculated properties
    const brighterColor = tinycolor(color).brighten(25);
    const background = {backgroundImage: "linear-gradient(to bottom, " + brighterColor.toString() + ", " + color + ")"};

    const fullScreenDefault = (!!document.fullscreenElement);
    const [isFullScreen, setIsFullScreen] = useState(fullScreenDefault);

    const renderTooltipOpenFullScreen = (props) => (
        <Tooltip id="button-tooltip" {...props}>Open in full screen</Tooltip>
    );
    const renderTooltipExitFullScreen = (props) => (
        <Tooltip id="button-tooltip" {...props}>Exit full screen</Tooltip>
    );

    function toggleFullScreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().then();
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen().then();
                setIsFullScreen(false);
            }
        }
    }

    const [isConnected, setIsConnected] = useState({client: false, therapist: false});
    const [handshakeReceived, setHandshakeReceived] = useState(false);

    // useEffect to connect to socket and start receiving messages
    useEffect(() => {
        initiateSocket(sessionName, clientType);
        // subscribe to client session and start receiving messages
        subscribeToClientSession((err, data) => {
            if (err) return;
            if (debugWs) console.log('data', data);
            handleAction(data);
        });
        return () => {
            disconnectSocket();
        }
    }, []);

    // useEffect to check every 500 ms if connected
    useEffect(() => {
        const interval = setInterval(() => {
            // Check is self is connected
            wsIsConnected((connectionStatus) => {
                setIsConnected(currentIsConnected => ({
                    ...currentIsConnected, [clientType]: connectionStatus
                }));
            });
        }, 500);
        return () => {
            clearInterval(interval);
        }
    }, []);

    /* useEffect to:
      - check if other party is connected (i.e. receive a handshake)
      - login if disconnected
      Runs every 500 ms
     */
    useEffect(() => {
        const timeout = setTimeout(function () {
            // Tell other party that you are connected
            if (!handshakeReceived || !isConnected[clientType]) {
                if (debugWs) console.log('Send log in from ' + clientType);
                sendAction('login');
            }
        }, 500);
        return () => {
            clearTimeout(timeout);
        }
    });

    const handleAction = (data) => {
        // Even though we use broadcast to send messages to other parties only (i.e. not echo to the sender),
        // there could be two clientType = client's connected and we only want to receive messages from other clientTypes
        if (clientType !== data.clientType) {
            if (debugWs) console.log('Received message from other party', data.clientType);
            if (debugWs) console.log('handle action', data);

            switch (data.action) {
                case 'login': {
                    if (debugWs) console.log('login received from', data.clientType);
                    setIsConnected(currentIsConnected => ({
                        ...currentIsConnected, [data.clientType]: true
                    }));
                    // Send handshake to confirm login was received
                    sendAction('handshake');
                    break;
                }
                case 'offline': {
                    if (debugWs) console.log('offline received from', data.clientType);
                    setIsConnected(currentIsConnected => ({
                        ...currentIsConnected, [data.clientType]: false
                    }));
                    break;
                }
                case 'handshake': {
                    if (debugWs) console.log('handshake received from', data.clientType);
                    setIsConnected(currentIsConnected => ({
                        ...currentIsConnected, [data.clientType]: true
                    }));
                    setHandshakeReceived(() => true);
                    break;
                }
                case 'start': {
                    showMessage('Started bilateral stimulation');
                    // Parse value into properties
                    const settings = data.actionData.split('@');
                    if (debugWs) console.log('Start stimulation settings', settings);
                    setSpeed(parseInt(settings[0]));
                    setColor(settings[1]);
                    setSound(settings[2]);
                    setMode(settings[3]);
                    setSessionStarted(true);
                    start().then();
                    break;
                }
                case 'stop': {
                    showMessage('Stopped bilateral stimulation');
                    if (debugWs) console.log('Stopped stimulation');
                    stop().then();
                    break;
                }
                case 'setSettings': {
                    //showMessage('Received bilateral stimulation settings');
                    // Parse value into properties
                    const settings = data.actionData.split('@');
                    if (debugWs) console.log('Received stimulation settings', settings);
                    setSpeed(parseInt(settings[0]));
                    setColor(settings[1]);
                    setSound(settings[2]);
                    setMode(settings[3]);
                    break;
                }
                case 'setSpeed': {
                    setSpeed(parseInt(data.actionData));
                    showMessage('Changed speed');
                    if (debugWs) console.log('Changed speed', data.actionData);
                    break;
                }
                case 'setColor': {
                    setColor(data.actionData);
                    showMessage('Changed color');
                    if (debugWs) console.log('Changed speed', data.actionData);
                    break;
                }
                case 'setSound': {
                    setSound(data.actionData);
                    showMessage('Changed sound to ' + data.actionData);
                    if (debugWs) console.log('Changed sound', data.actionData);
                    break;
                }
                case 'setMode': {
                    setMode(data.actionData);
                    showMessage('Changed mode to ' + data.actionData);
                    if (debugWs) console.log('Changed mode', data.actionData);
                    break;
                }
            }
        }
    }

    const [sessionStarted, setSessionStarted] = useState(false);

    const playSound = (sound, channel) => {
        if (sound !== 'No sound') {
            const url = `/sounds/${sound.toLowerCase()}_${channel}.mp3`;
            if (debug) console.log('sound', url);
            const audio = new Audio(url);
            audio.play().then();
        }
    }

    const controls = useAnimation();

    let isRunning = false;

    const start = async () => {
        if (!isRunning) {
            isRunning = true;
            if (modeRef.current === 'Smooth' ||modeRef.current === 'Pursuit' || modeRef.current === 'Sound only') {
                await controls.start("middleToLeft").then();
                playSound(soundRef.current, 'l');
                while (isRunning) {
                    await controls.start("leftToRight").then();
                    playSound(soundRef.current, 'r');
                    await controls.start("rightToLeft").then();
                    playSound(soundRef.current, 'l');
                }
            }
            else {
                await controls.start("pulseDown").then();
                while (isRunning) {
                    await controls.start("transitionLeft").then();
                    await controls.start("pulseUp").then();
                    playSound(soundRef.current, 'l');
                    await controls.start("pulseDown").then();
                    await controls.start("transitionRight").then();
                    await controls.start("pulseUp").then();
                    playSound(soundRef.current, 'r');
                    await controls.start("pulseDown").then();
                }
            }
        }
    }

    const stop = async () => {
        isRunning = false;
        if (modeRef.current === 'Smooth' ||modeRef.current === 'Pursuit' || modeRef.current === 'Sound only') {
            controls.start("backToMiddle").then();
        }
        else {
            await controls.start("pulseDown").then();
            await controls.start("transitionMiddle").then();
            controls.start("pulseUp").then();
        }
    }

    const duration = 400 / (speed * 10);
    const halfDuration = duration / 2;
    const margin = 40
    const leftMargin = margin / 2;
    const rightMargin = window.innerWidth - (2 * margin) - (margin / 2);
    const xInitial = window.innerWidth / 2 - leftMargin;
    const yInitial = margin;
    const stiffness = 30;
    const ease = (modeRef.current === 'Smooth') ? "linear" : "";
    const pulseEase = "linear";

    const variants = {
        middleToLeft: {
            x: leftMargin,
            transition: {
                ease: ease,
                stiffness: stiffness,
                duration: halfDuration,
            }
        },

        leftToRight: {
            x: [leftMargin, rightMargin],
            transition: {
                ease: ease,
                stiffness: stiffness,
                duration: duration
            }
        },

        rightToLeft: {
            x: [rightMargin, leftMargin],
            transition: {
                ease: ease,
                stiffness: stiffness,
                duration: duration
            }
        },

        backToMiddle: {
            x: xInitial,
            transition: {
                ease: ease
            }
        },

        transitionLeft: {
            // immediately move object to the left and scale to 0
            x: leftMargin,
            transition: {
                duration: 0
            },
            animate: {
                scale: 0.001
            }
        },

        transitionRight: {
            // immediately move object to the right and scale to 0
            x: rightMargin,
            transition: {
                duration: 0
            },
            animate: {
                scale: 0.001
            }
        },

        transitionMiddle: {
            // immediately move object to the middle and scale to 0
            x: xInitial,
            transition: {
                duration: 0
            },
            animate: {
                scale: 0.001
            }
        },

        pulseUp: {
            scale: 1,
            transition: {
                ease: pulseEase,
                duration: halfDuration,
            }
        },

        pulseDown: {
            scale: 0.001,
            transition: {
                ease: pulseEase,
                duration: halfDuration,
            }
        }

    };

    return (
        <>
            {!clientSessionExists &&
            <>
                <PageHeading title={`EMDR Bilateral Stimulation session '${sessionName}' does not exist`}/>
                <p className="container">
                    Please verify the session name with your therapist.<br/>
                    <Button className="mt-3 mr-3" onClick={handleBack}>Go back</Button>
                </p>

            </>
            }

            {clientSessionExists && isConnected.therapist &&
            <motion.div className={modeRef.current === 'Sound only' ? 'hidden' : 'object ball'}
                        initial={{x: xInitial, y: yInitial}} style={background}
                        variants={variants} animate={controls}/>
            }

            <div className="container">

                {clientSessionExists && !isConnected.therapist &&
                <>
                    <PageHeading title={`EMDR Bilateral Stimulation session: ${sessionName}`}/>
                    {!sessionStarted &&
                    <p>Please wait until your therapist is online.</p>
                    }
                    {sessionStarted && !isConnected.client &&
                    <p>The connection to your therapist was lost. Please wait or reload the page to reestablish the
                        connection.</p>
                    }
                    {sessionStarted && !isConnected.therapist &&
                    <p>
                        Your therapist lost or closed the connection.<br/>
                        If your session is finished you can close this window.<br/>
                        Otherwise please wait until your therapist comes back on-line.
                    </p>
                    }
                </>
                }

                <AnimatePresence>
                    {showCardModal &&
                    <motion.div
                        initial={{x: 200}}
                        variants={cardModalVariants}
                        animate="show"
                        exit={{opacity: 0, transition: {duration: 1}}}
                        style={{position: "fixed", zIndex: 10, bottom: "80px", right: "40px"}}>
                        <Card className="" onClose={() => setShowCardModal(false)}>
                            <Card.Body>{cardModalBody}</Card.Body>
                        </Card>
                    </motion.div>
                    }
                </AnimatePresence>

                <div id="fullscreenToggle" style={{position: "fixed", bottom: "80px", right: "40px"}}>
                    {isFullScreen &&
                    <OverlayTrigger placement="top" delay={{show: 250, hide: 400}}
                                    overlay={renderTooltipExitFullScreen}>
                        <FontAwesomeIcon className="ml-2 mr-2" size="2x" style={{cursor: "pointer"}} icon={faCompress}
                                         onClick={toggleFullScreen}/>
                    </OverlayTrigger>
                    }
                    {!isFullScreen &&
                    <OverlayTrigger placement="top" delay={{show: 250, hide: 400}}
                                    overlay={renderTooltipOpenFullScreen}>
                        <FontAwesomeIcon className="ml-2 mr-2" size="2x" style={{cursor: "pointer"}} icon={faExpand}
                                         onClick={toggleFullScreen}/>
                    </OverlayTrigger>
                    }
                </div>

            </div>

            {clientSessionExists &&
            <div style={{position: "fixed", bottom: "80px", left: "40px"}}>
                {isConnected.client &&
                <small className="text-muted"><FontAwesomeIcon className="mr-2 online" icon={faGlobe}/>Client is
                    online<br/></small>
                }
                {!isConnected.client &&
                <small className="text-muted"><FontAwesomeIcon className="mr-2 offline" icon={faGlobe}/>Client is
                    offline<br/></small>
                }
                {isConnected.therapist &&
                <small className="text-muted"><FontAwesomeIcon className="mr-2 online" icon={faGlobe}/>Therapist is
                    online<br/></small>
                }
                {!isConnected.therapist &&
                <small className="text-muted"><FontAwesomeIcon className="mr-2 offline" icon={faGlobe}/>Therapist is
                    offline<br/></small>
                }
            </div>
            }

            <Footer/>
        </>
    )

}

export default ClientSession;
