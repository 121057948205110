import React, {useState} from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton';
import HelpTopics from "./HelpTopics";
import PageHeading from "../partials/PageHeading";
import Footer from "../partials/Footer";

const Help = () => {

    const [radioValue, setRadioValue] = useState('therapist');

    const radios = [
        { name: 'Therapist', value: 'therapist' },
        { name: 'Client', value: 'client' },
    ];

    const onChange = (e) => {
        setRadioValue(e.currentTarget.value);
    }

    return (
        <div className="vh-100">
            <PageHeading title='EMDR Bilateral Stimulator' />
            <div className="container d-flex align-items-center justify-content-center mb-3">
                <span className="mr-3">Show help topics for:</span>
                <ButtonGroup toggle>
                    {radios.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            type="radio"
                            variant={(radioValue === radio.value) ? 'primary' : 'secondary'}
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={onChange}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
            </div>
            <HelpTopics category={radioValue}/>
            <Footer/>
        </div>
    )
}
export default Help;
