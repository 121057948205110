import React, {useContext} from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import {AuthContext} from "../context/AuthContext";

const ProtectedRoute = ({ component: Component, role: role, ...rest }) => {
    const location = useLocation();
    const {user, isAuthenticated} = useContext(AuthContext);

    // Determine if user is authorized to this resource
    const authorized = (
        (typeof role === 'undefined' && isAuthenticated) ||
        (typeof role !== 'undefined' && user.role === role)
    );

    return (
        <Route {...rest}>
            {!authorized &&
                <Redirect to={{ pathname: "/therapist/login", state: { from: location } }} />
            }
            {authorized &&
                <Component {...rest}/>
            }
        </Route>
    )
};

export default ProtectedRoute;
