import React, {useState, useEffect} from 'react';
import AuthService from '../../services/AuthService';
import PageHeading from "../partials/PageHeading";
import Button from 'react-bootstrap/Button';

const debug = false;

const VerifyEmail = (props) => {

    const [emailVerificationHashVerified, setEmailVerificationHashVerified] = useState(false);

    useEffect(() => {
        const verifyEmailVerificationHash = async () => {
            if (debug) console.log('params', props.match.params);
            // Verify password hash and get user _id and email
            const {message} = await AuthService.verifyEmailHash(props.match.params.email, props.match.params.emailVerificationHash);
            if (debug) console.log('verifyEmail message', message);
            if (!message.msgError) {
                setEmailVerificationHashVerified(true);
            }
        }
        verifyEmailVerificationHash().then();
    }, []);


    const handleBackToHome = () => {
        props.history.push('/');
    }

    return (
        <div className="container">
            <PageHeading title='EMDR Bilateral Stimulator' />
            <h3 className="mb-3">Verify email</h3>
            {emailVerificationHashVerified &&
            <>
                <p>Thank you for confirming your email address.</p>
            </>
            }
            {!emailVerificationHashVerified &&
            <>
                <p>
                    We couldn't verify your email address. The verification link may have expired or you may have verified your email address already.<br/>
                    <br/>
                    If your email address isn't verified yet, you will see a message on the 'Create a new session' page and can request a new link there.
                </p>
            </>
            }
            <Button onClick={handleBackToHome} style={{cursor: "pointer"}}>Back to home</Button>
        </div>
    )
}

export default VerifyEmail;
