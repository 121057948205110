const debug = false;

const helpTopicsReducer = (helpTopics = [], action) => {

    switch (action.type) {

        case 'FETCH_ALL_HELPTOPICS': {
            if (debug) console.log('FETCH_ALL_HELPTOPICS');
            return action.payload;
        }

        case 'FETCH_HELPTOPIC': {
            if (debug) console.log('FETCH_HELPTOPIC');
            return helpTopics.map((helpTopic) => (helpTopic._id === action.payload._id ? action.payload : helpTopic));
        }

        case 'CREATE_HELPTOPIC': {
            if (debug) console.log('CREATE_HELPTOPIC', action.payload);
            return [...helpTopics, action.payload];
        }

        case 'UPDATE_HELPTOPIC': {
            if (debug) console.log('UPDATE_HELPTOPIC',action.payload);
            return helpTopics.map((helpTopic) => (helpTopic._id === action.payload._id ? action.payload : helpTopic));
        }

        case 'DELETE_HELPTOPIC': {
            if (debug) console.log('DELETE_HELPTOPIC');
            return helpTopics.filter((helpTopic) => helpTopic._id !== action.payload);
        }

        default:
            return helpTopics;
    }
}

export default helpTopicsReducer
