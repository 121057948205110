import React, {useContext} from 'react'
import {Alert} from "react-bootstrap";
import {AuthContext} from '../../context/AuthContext';

const Message = (props) => {

    const {user} = useContext(AuthContext);

    const getStyle = (props) => {
        let baseClass = "alert ";
        if (props.message.msgError)
            baseClass = baseClass + "alert-warning";
        else
            baseClass = baseClass + "alert-primary";
        return baseClass + " text-center";
    }

    return (
        <Alert variant={getStyle(props)}>
            <span dangerouslySetInnerHTML={{ __html: props.message.msgBody}}/>
            {(user.role === "admin" && (typeof props.message.msgDetail !== 'undefined')) ? `<span><br/>${props.message.msgDetail}</span>` : null}
        </Alert>
    )
}

export default Message;
