const debug = false;

const therapistsReducer = (therapists = [], action) => {

    switch (action.type) {

        case 'FETCH_ALL_THERAPISTS': {
            if (debug) console.log('FETCH_ALL_THERAPISTS');
            if (debug) console.log('payload', action.payload);
            return action.payload;
        }

         case 'DELETE_THERAPIST': {
            if (debug) console.log('DELETE_THERAPIST');
            return therapists.filter((therapist) => therapist._id !== action.payload);
        }

        default:
            return therapists;
    }
}

export default therapistsReducer
