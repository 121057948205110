import React, {useContext, useState} from "react";
import {useHistory} from "react-router";
import {Link, Route} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import AuthService from "../../services/AuthService";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {AnimatePresence, motion} from "framer-motion";
import Card from "react-bootstrap/Card";

const debug = false;

const Footer = () => {

    const {user, setUser, isAuthenticated, setIsAuthenticated} = useContext(AuthContext);

    const [showProfileModal, setShowProfileModal] = useState(false);

    const profileModalVariants = {
        show: {
            x: 0,
            transition: {duration: 0.5}
        }
    }

    const displayProfileModal = () => {
        setShowProfileModal(true);
        setTimeout(() => {
            setShowProfileModal(false)
        }, 3000);
    };

    const onClickEditProfileHandler = () => {
        if (debug) console.log('editProfileHandler');
        // Immediately hide profile modal
        setShowProfileModal(false);
        history.push(`/therapist/editProfile`);
    }

    if (debug) console.log('isAuthenticated', isAuthenticated, 'user', user);
    const initials = (
        (isAuthenticated !== 'undefined' && user.firstname !== '' && user.lastname !== '') ?
            (user.firstname[0] + user.lastname[0]).toUpperCase() :
            '');

    const history = useHistory();

    const onClickLogoutHandler = () => {
        if (debug) console.log('logoutHandler');
        AuthService.logout().then(data => {
            if (debug) console.log('data', data);
            if (data.success) {
                if (debug) console.log('success');
                setUser(data.user);
                setIsAuthenticated(false);
                // Redirect to homepage
                history.push(`/`);
            }
        });
    }

    const unauthenticatedNavBar = () => {
        return (
            <>
                <Link to="/therapist/login">
                    <li className="nav-item nav-link">
                        Login
                    </li>
                </Link>
                <Link to="/therapist/register">
                    <li className="nav-item nav-link">
                        Register
                    </li>
                </Link>
            </>
        )
    }

    const authenticatedNavBar = () => {
        return (
            <>
                <Link to="#" onClick={onClickLogoutHandler}>
                    <li className="nav-item nav-link">
                        Logout
                    </li>
                </Link>
                {user.role === "admin" ?
                    <Link to="/admin">
                        <li className="nav-item nav-link">
                            Admin
                        </li>
                    </Link> : null
                }
            </>
        )
    }

    function hideProfileModal() {
        const timeout = setTimeout(() => {
            setShowProfileModal(false);
        }, 3000);

        return clearTimeout(timeout);
    }

    return (
        <>
            <Navbar className="mt-lg-5" fixed="bottom" bg="dark" variant="dark">

                <Navbar.Collapse className="align-items-center justify-content-center"
                                 style={{position: "absolute", width: "100%", left: "0px"}}>
                    <Navbar.Text className="text-muted">
                         Copyright {'\u00a9'} {(new Date().getFullYear())}&nbsp;
                        <a className="text-muted" href="mailto:patrick@what-about-pat.com?subject=EMDR%20Bilateral%20Stimulator">
                            Patrick Willart
                        </a>
                    </Navbar.Text>
                </Navbar.Collapse>

                <Nav className="mr-auto" style={{zIndex: "3"}}>
                    <Link to="/">
                        <li className="nav-item nav-link">Home</li>
                    </Link>
                    <Link to="/help">
                        <li className="nav-item nav-link">Help</li>
                    </Link>
                    <Route path={['/therapist']}>
                        {!isAuthenticated &&
                        unauthenticatedNavBar()
                        }
                    </Route>
                    {isAuthenticated &&
                    authenticatedNavBar()
                    }
                </Nav>

                {isAuthenticated &&
                <Nav className="ml-auto" style={{zIndex: "3"}}>
                    <li className="nav-item">
                        <div className="avatar bg-primary" onMouseOver={() => displayProfileModal()}
                             onMouseOut={() => hideProfileModal()}>{initials}</div>
                    </li>
                </Nav>
                }

            </Navbar>

        <AnimatePresence>
            {showProfileModal &&
            <motion.div
                initial={{x: 200}}
                variants={profileModalVariants}
                animate="show"
                exit={{opacity: 0, transition: {duration: 1}}}
                style={{position: "fixed", zIndex: 10, bottom: "80px", right: "40px"}}>
                <Card onClose={() => setShowProfileModal(false)}>
                    <Card.Body>
                        Logged in as {user.firstname} {user.lastname}<br/>
                        <Link to="#" onClick={onClickEditProfileHandler}>Edit profile</Link>
                    </Card.Body>
                </Card>
            </motion.div>
            }
        </AnimatePresence>
    </>
    )
}

export default Footer;
