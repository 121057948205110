import React from 'react'

const PageHeading = (props) => {
    return (
        <div className="w-100 d-inline-block text-center mb-3">
            <h1 className="h-3 mt-5">{props.title ? props.title : 'EMDR Bilateral Stimulator'}</h1>
        </div>
    )
};

export default PageHeading;
