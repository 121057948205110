import axios from 'axios';

const debug = false;

const url = '/api/user';

export const fetchAllTherapists = () => {
    if (debug) console.log('fetchAllTherapists api call')
    return axios.get(`${url}/list`);
};

export const deleteTherapist = (id) => {
    if (debug) console.log(`deleteTherapist api call, id: ${id}`);
    return axios.delete(`${url}/${id}`);
}
