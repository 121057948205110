const debug = false;

const initialState = {
    allClientSessions: [],
    therapistClientSessions: [],
    clientSession: {},
    clientSessionExists: false
}

const clientSessionsReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'FETCH_ALL_CLIENTSESSIONS': {
            if (debug) console.log('FETCH_ALL_CLIENTSESSIONS');
            if (debug) console.log('payload', action.payload);
            return {
                ...state,
                allClientSessions: action.payload
            }
        }

        case 'FETCH_THERAPIST_CLIENTSESSIONS': {
            if (debug) console.log('FETCH_THERAPIST_CLIENTSESSIONS');
            if (debug) console.log('payload', action.payload);
            return {
                ...state,
                therapistClientSessions: action.payload
            }
        }

        case 'FETCH_CLIENTSESSION': {
            if (debug) console.log('FETCH_CLIENTSESSION');
            if (debug) console.log('payload', action.payload);
            return {
                ...state,
                clientSession: action.payload
            }
        }

        case 'EXISTS_CLIENTSESSION': {
            if (debug) console.log('EXISTS_CLIENTSESSION');
            if (debug) console.log('payload', action.payload);
            return {
                ...state,
                clientSessionExists: action.payload
            }
        }

        case 'CREATE_CLIENTSESSION': {
            if (debug) console.log('CREATE_CLIENTSESSION');
            return {
                allClientSessions : [...state.allClientSessions, action.payload],
                therapistClientSessions : [...state.therapistClientSessions, action.payload],
                clientSession: action.payload
            }
        }

        case 'UPDATE_CLIENTSESSION': {
            if (debug) console.log('UPDATE_CLIENTSESSION', action.payload);
            return {
                //...state,
                allClientSessions: state.allClientSessions.map((clientSession) => (clientSession._id === action.payload._id ? action.payload : clientSession)),
                therapistClientSessions: state.therapistClientSessions.map((clientSession) => (clientSession._id === action.payload._id ? action.payload : clientSession)),
                clientSession: action.payload
            }
        }

        case 'DELETE_CLIENTSESSION': {
            if (debug) console.log('DELETE_CLIENTSESSION');
            return {
                ...state,
                allClientSessions: state.allClientSessions.filter((clientSession) => clientSession._id !== action.payload),
                therapistClientSessions: state.therapistClientSessions.filter((clientSession) => clientSession._id !== action.payload),
            };
        }

        default:
            return state;
    }
}

export default clientSessionsReducer
