import React, {useContext, useEffect, useRef, useState} from 'react';
import { initiateSocket, disconnectSocket, subscribeToClientSession, isConnected as wsIsConnected, sendAction } from './../../services/WebSocketClient';
import {AnimatePresence, motion, useAnimation} from "framer-motion";
import PageHeading from "../partials/PageHeading";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCompress, faCopy, faExpand, faGlobe} from '@fortawesome/free-solid-svg-icons'
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import RangeSlider from 'react-bootstrap-range-slider';
import {CirclePicker} from "react-color";
import tinycolor from "tinycolor2";
import Card from "react-bootstrap/Card";
import {useDispatch, useSelector} from "react-redux";
import {fetchClientSession, updateClientSession} from "../../store/actions/clientSessionActions";
import _ from "lodash";
import Footer from "../partials/Footer";
import {AuthContext} from "../../context/AuthContext";

const TherapistSession = (props) => {

    const debug = false;
    const debugWs = false;

    // We need to use computed match because of the ProtectedRoute hoc
    const sessionName = props.computedMatch.params.name;
    if (debug) console.log('sessionName', sessionName);

    const clientType = 'therapist';

    const dispatch = useDispatch();

    const {isAuthenticated} = useContext(AuthContext);

    const [initCompleted, setInitCompleted] = useState(false);

    useEffect(() => {
        async function init() {
            if (debug) console.log(`initState TherapistSession: ${sessionName}`);
            await dispatch(fetchClientSession(sessionName));
            setInitCompleted(true);
        }

        init().then();
    }, []);

    useEffect(() => {
        if (initCompleted) {
            // Redirect user to therapist page if not authenticated or clientSession is empty
            if (!isAuthenticated || _.isEmpty(clientSession)) {
                props.history.push('/therapist');
            }
            // The slider control is a bit different and it's value needs to be set after clientSession is loaded
            setDisplaySpeed(clientSession.speed);
            setSoundButtonText(clientSession.sound);
            //dispatch(updateClientSession({...clientSession}));
        }
    }, [initCompleted]);

    const clientSession = useSelector((state) => state.clientSessions.clientSession);
    if (debug) console.log('clientSession', clientSession);

    const handleBack = () => {
        props.history.push('/therapist');
    }

    const [showCardModal, setShowCardModal] = useState(false);
    const [cardModalBody, setCardModalBody] = useState('');

    const cardModalVariants = {
        show: {
            x: 0,
            transition: {duration: 0.5}
        }
    }

    const showMessage = ((body) => {
        setCardModalBody(body);
        setShowCardModal(true);
        setTimeout(() => {
            setShowCardModal(false)
        }, 1000);
    });

    const clientSessionLink = window.location.href.replace('/therapist/', '/client/');

    const [tooltipText, setTooltipText] = useState('Click to copy');

    const resetTooltipText = () => {
        // Add a little delay to make sure the tooltip is hidden before changing back the text
        setTimeout(() => {
            setTooltipText('Click to copy')
        }, 100);
    }
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>{tooltipText}</Tooltip>
    );

    function copyToClipboard(text) {
        let tempInput = document.createElement("input");
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        setTooltipText('Copied!');
    }

    function copyNameToClipboard() {
        copyToClipboard(sessionName);
        // fetch 'button-tooltip' element and set it's content to 'Copied
    }

    function copyLinkToClipboard() {
        copyToClipboard(clientSessionLink);
    }

    const [isConnected, setIsConnected] = useState({client: false, therapist: false});
    const [handshakeReceived, setHandshakeReceived] = useState(false);

    // useEffect to connect to socket and start receiving messages
    useEffect(() => {
        initiateSocket(sessionName, clientType);
        // subscribe to client session and start receiving messages
        subscribeToClientSession((err, data) => {
            if (err) return;
            if (debugWs) console.log('data', data);
            handleAction(data);
        });
        return () => {
            disconnectSocket();
        }
    }, [initCompleted]);

    // useEffect to check every 500 ms if connected
    useEffect(() => {
        const interval = setInterval(() => {
            // Check is self is connected
            wsIsConnected((connectionStatus) => {
                setIsConnected(currentIsConnected => ({
                    ...currentIsConnected, [clientType]: connectionStatus
                }));
            });
        }, 500);
        return () => {
            clearInterval(interval);
        }
    }, []);

    /* useEffect to:
      - check if other party is connected (i.e. receive a handshake)
      - login if disconnected
      Runs every 500 ms
     */
    useEffect(() => {
        const timeout = setTimeout(function () {
            // Tell other party that you are connected
            if (!handshakeReceived || !isConnected[clientType]) {
                if (debugWs) console.log('Send log in from ' + clientType);
                sendAction('login');
            }
        }, 500);
        return () => {
            clearTimeout(timeout);
        }
    });

    const handleAction = (data) => {
        // Even though we use broadcast to send messages to other parties only (i.e. not echo to the sender),
        // there could be two clientType = client's connected and we only want to receive messages from other clientTypes
        if (clientType !== data.clientType) {
            if (debugWs) console.log('Received message from other party', data.clientType);
            if (debugWs) console.log('handle action', data);

            switch (data.action) {
                case 'login': {
                    if (debugWs) console.log('login received from', data.clientType);
                    setIsConnected(currentIsConnected => ({
                        ...currentIsConnected, [data.clientType]: true
                    }));
                    // Send handshake to confirm login was received
                    sendAction('handshake');
                    // Send bilateral stimulation settings to client
                    break;
                }
                case 'offline': {
                    if (debugWs) console.log('offline received from', data.clientType);
                    setIsConnected(currentIsConnected => ({
                        ...currentIsConnected, [data.clientType]: false
                    }));
                    break;
                }
                case 'handshake': {
                    if (debugWs) console.log('handshake received from', data.clientType);
                    setIsConnected(currentIsConnected => ({
                        ...currentIsConnected, [data.clientType]: true
                    }));
                    setHandshakeReceived(() => true);
                    // Send bilateral stimulation settings to client
                    if (debugWs) console.log(`Bilateral stimulation settings ${clientSession.speed}@${clientSession.color}@${clientSession.sound}@${clientSession.mode}`);
                    sendAction('setSettings', `${clientSession.speed}@${clientSession.color}@${clientSession.sound}@${clientSession.mode}`);
                    break;
                }
            }
        }
    }

    const sounds = ["Beep", "Chirp", "Ding", "Dong", "Percussion", "Thumb"];

    const colors = [
        "#ffe500", "#ffbc00", "#ff9300", "#ff5800",
        "#df0020", "#9f0060", "#60009f", "#2000df",
        "#0028bf", "#007840", "#40b100", "#bfd400"
    ];

    const modes = [ "Smooth", "Pursuit", "Pulse", "Sound only"];

    const fullScreenDefault = (!!document.fullscreenElement);
    const [isFullScreen, setIsFullScreen] = useState(fullScreenDefault);

    const renderTooltipOpenFullScreen = (props) => (
        <Tooltip id="button-tooltip" {...props}>Open in full screen</Tooltip>
    );
    const renderTooltipExitFullScreen = (props) => (
        <Tooltip id="button-tooltip" {...props}>Exit full screen</Tooltip>
    );

    function toggleFullScreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().then();
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen().then();
                setIsFullScreen(false);
            }
        }
    }

    /* Form values */
    const [counter, setCounter] = useState(0);
    const [sets, setSets] = useState(0);
    const [triggerRunLabel, setTriggerRunLabel] = useState('Start');
    const [triggerTestLabel, setTriggerTestLabel] = useState('Test');
    // A separate display value is used to not immediately trigger a speed change of the bilateral stimulator.
    // It can't be changed immediately because the command needs to be send over to the client.
    // If a command is send immediately when the slider is moved we trigger too many events
    // The displaySpeed is updated immediately to give the therapist feedback on the new value
    const [displaySpeed, setDisplaySpeed] = useState(50); // Default value is set in the initCompleted useEffect()
    const soundRef = useRef();
    // noinspection JSValidateTypes
    soundRef.current = _.isEmpty(clientSession.sound) ? "No sound" : clientSession.sound;
    const [soundButtonText, setSoundButtonText] = useState("No sound");
    const soundButtonTextRef = useRef();
    // noinspection JSValidateTypes
    soundButtonTextRef.current = soundButtonText;
    const color = _.isEmpty(clientSession.color) ? colors[3] : clientSession.color;
    const brighterColor = tinycolor(color).brighten(25);

    const background = {backgroundImage: "linear-gradient(to bottom, " + brighterColor.toString() + ", " + color + ")"};

    const handleChangeSpeed = (e) => {
        setDisplaySpeed(e.target.value);
    }

    // Only send the changed speed after the slider is stopped being dragged
    const handleAfterChangeSpeed = (e) => {
        //setSpeed(e.target.value);
        if (debug) console.log("Sending new speed to client", e.target.value);
        sendAction('setSpeed', e.target.value);
        dispatch(updateClientSession({...clientSession, speed: e.target.value}));
    }

    const handleChangeColor = (color) => {
        sendAction('setColor', color.hex);
        dispatch(updateClientSession({...clientSession, color: color.hex}));
    }

    const playSound = (sound, channel, ignoreMute = false) => {
        if (sound !== 'No sound') {
            if (debug) console.log('ignoreMute', ignoreMute, 'cs.mute:', clientSession.mute);
            if (ignoreMute || !clientSession.mute) {
                const audio = new Audio('/sounds/' + sound.toLowerCase() + "_" + channel + ".mp3");
                audio.play().then();
            }
        }
    }

    const handleChangeSound = (sound) => {
        sendAction('setSound', sound);
        //setSound(sound);
        setSoundButtonText(sound);
        if (sound !== 'No sound') {
            playSound(sound, 'l', true); // On change sound we want to give feedback to the therapist on what sound is selected
        }
        dispatch(updateClientSession({...clientSession, sound: sound}));
    }

    const soundsList = sounds.map((sound, idx) =>
        <Dropdown.Item key={idx} eventKey={sound} onSelect={handleChangeSound}>{sound}</Dropdown.Item>
    );

    const handleChangeMute = () => {
        if (debug) console.log('changeMute before clientSession.mute', clientSession.mute);
        dispatch(updateClientSession({...clientSession, mute: !clientSession.mute}));
    }

    const handleChangeMode = (mode) => {
        sendAction('setMode', mode);
        dispatch(updateClientSession({...clientSession, mode: mode}));
    }

    const modesList = modes.map((mode, idx) =>
        <Dropdown.Item key={idx} eventKey={mode} onSelect={handleChangeMode}>{mode}</Dropdown.Item>
    );


    const handleReset = () => {
        setCounter(0);
        setSets(0);
    }

    const controls = useAnimation();

    let isRunning = false;
    let runningCounter = 0;

    const handleTrigger = async (test) => {
        if (!test) {
            if (!isConnected.client) {
                showMessage('Please wait until your client is online');
                return;
            }
            if (!isConnected.therapist) {
                showMessage('Please wait until your connection with the server is established');
                return;
            }
        }
        if ((test && triggerTestLabel === 'Test') || (!test && triggerRunLabel === 'Start')) {
            // Disable 'other' button
            if (test) {
                setTriggerTestLabel('Stop test');
                document.getElementById('runTrigger').disabled = true;
            } else {
                sendAction('start', `${clientSession.speed}@${clientSession.color}@${clientSession.sound}@${clientSession.mode}`);
                setTriggerRunLabel('Stop');
                document.getElementById('testTrigger').disabled = true;
            }
            isRunning = true;
            runningCounter = 0;
            setCounter(runningCounter);
            setSets(sets + 1);
            // Note: still play animation for 'Sound only' because of timing (i.e. speed in framer motion actions)
            if (clientSession.mode === 'Smooth' || clientSession.mode === 'Pursuit' || clientSession.mode === 'Sound only') {
                await controls.start("middleToLeft").then();
                playSound(soundRef.current, 'l');
                while (isRunning) {
                    await controls.start("leftToRight").then();
                    playSound(soundRef.current, 'r');
                    runningCounter++;
                    await controls.start("rightToLeft").then();
                    playSound(soundRef.current, 'l');
                    setCounter(runningCounter);
                }
            }
            else {
                await controls.start("pulseDown").then();
                while (isRunning) {
                    await controls.start("transitionLeft").then();
                    await controls.start("pulseUp").then();
                    playSound(soundRef.current, 'l');
                    await controls.start("pulseDown").then();
                    await controls.start("transitionRight").then();
                    await controls.start("pulseUp").then();
                    playSound(soundRef.current, 'r');
                    await controls.start("pulseDown").then();
                    runningCounter++;
                    setCounter(runningCounter);
                }
            }
        }
        if ((test && triggerTestLabel === 'Stop test') || (!test && triggerRunLabel === 'Stop')) {
            if (test) {
                setTriggerTestLabel('Test');
                document.getElementById('runTrigger').disabled = false;
            } else {
                sendAction('stop');
                setTriggerRunLabel('Start');
                document.getElementById('testTrigger').disabled = false;
            }
            isRunning = false;
            // Return object to middle even if sound only in case mode will be changed back to a visible mode later
            if (clientSession.mode === 'Smooth' || clientSession.mode === 'Pursuit' || clientSession.mode === 'Sound only') {
                controls.start("backToMiddle").then();
            }
            else {
                await controls.start("pulseDown").then();
                await controls.start("transitionMiddle").then();
                controls.start("pulseUp").then();
            }
        }
    }

    const handleTriggerRun = async () => {
        await handleTrigger(false);
    }

    const handleTriggerTest = async () => {
        await handleTrigger(true);
    }

    const duration = 400 / (clientSession.speed * 10);
    const halfDuration = duration / 2;
    const margin = 40
    const leftMargin = margin / 2;
    const rightMargin = window.innerWidth - (2 * margin) - (margin / 2);
    const xInitial = window.innerWidth / 2 - leftMargin;
    const yInitial = margin;
    const stiffness = 30;
    const ease = (clientSession.mode === 'Smooth') ? "linear" : "";
    const pulseEase = "linear";

    //console.log('stiffness: '+stiffness+'; type: '+type);

    const variants = {
        middleToLeft: {
            x: leftMargin,
            transition: {
                ease: ease,
                stiffness: stiffness,
                duration: halfDuration,
            }
        },

        leftToRight: {
            x: [leftMargin, rightMargin],
            transition: {
                ease: ease,
                stiffness: stiffness,
                duration: duration,
            }
        },

        rightToLeft: {
            x: [rightMargin, leftMargin],
            transition: {
                ease: ease,
                stiffness: stiffness,
                duration: duration,
            }
        },

        backToMiddle: {
            x: xInitial,
            transition: {
                ease: ease
            }
        },

        transitionLeft: {
            // immediately move object to the left and scale to 0
            x: leftMargin,
            transition: {
                duration: 0
            },
            animate: {
              scale: 0.001
            }
        },

        transitionRight: {
            // immediately move object to the right and scale to 0
            x: rightMargin,
            transition: {
                duration: 0
            },
            animate: {
                scale: 0.001
            }
        },

        transitionMiddle: {
            // immediately move object to the middle and scale to 0
            x: xInitial,
            transition: {
                duration: 0
            },
            animate: {
                scale: 0.001
            }
        },

        pulseUp: {
            scale: 1,
            transition: {
                ease: pulseEase,
                duration: halfDuration,
            }
        },

        pulseDown: {
            scale: 0.001,
            transition: {
                type: pulseEase,
                duration: halfDuration,
            }
        }

    };

    return (
        <div className="vh-100">

            {_.isEmpty(clientSession) &&
            <>
                <PageHeading title={`EMDR Bilateral Stimulation session '${sessionName}' does not exist`}/>
                <p className="container">
                    Please verify the session name.<br/>
                    <Button className="mt-3 mr-3" onClick={handleBack}>Go back</Button>
                </p>

            </>
            }

            {!_.isEmpty(clientSession) &&
            <>
                <PageHeading title={`EMDR Bilateral Stimulation session: ${sessionName}`}/>

                {!isConnected.client &&
                <div className="container">
                    <p>Provide your client with this session name
                        <code className="ml-2">{sessionName}</code>
                        <OverlayTrigger placement="top" delay={{show: 250, hide: 400}} onToggle={resetTooltipText}
                                        overlay={renderTooltip}>
                            <FontAwesomeIcon className="ml-2 mr-2" style={{cursor: "pointer"}} icon={faCopy}
                                             onClick={copyNameToClipboard}/>
                        </OverlayTrigger>
                        or send them this link:
                        <code className="ml-2">{clientSessionLink}</code>
                        <OverlayTrigger placement="top" delay={{show: 250, hide: 400}} onToggle={resetTooltipText}
                                        overlay={renderTooltip}>
                            <FontAwesomeIcon className="ml-2 mr-2" style={{cursor: "pointer"}} icon={faCopy}
                                             onClick={copyLinkToClipboard}/>
                        </OverlayTrigger>
                    </p>
                </div>
                }

                <motion.div className={clientSession.mode === 'Sound only' ? 'hidden' : 'object ball'}
                            initial={{x: xInitial, y: yInitial}} style={background}
                            variants={variants} animate={controls}/>

                <div id="spacer" className="container mb-5">&nbsp;</div>

                <div className="justify-content-center">
                    <Form className="container mt-5">
                        <Form.Group as={Row} controlId="speed">
                            <Col xs="2">
                                <Form.Label>Speed</Form.Label>
                            </Col>
                            <Col xs="6">
                                <RangeSlider min={1} max={100} value={displaySpeed} tooltip="off"
                                             onChange={handleChangeSpeed} onAfterChange={handleAfterChangeSpeed}/>
                                <div className={"w-100 text-center"}>{displaySpeed}</div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs="2">
                                <Form.Label>Color</Form.Label>
                            </Col>
                            <Col xs="5">
                                <CirclePicker circleSize={26} circleSpacing={12} width={500} color={color}
                                              colors={colors} onChangeComplete={handleChangeColor}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-0">
                            <Col xs="2">
                                <Form.Label>Sound</Form.Label>
                            </Col>
                            <Col xs="5">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-sound">{clientSession.sound}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item key='ns' eventKey={'No sound'} onSelect={handleChangeSound}>No
                                            sound</Dropdown.Item>
                                        {soundsList}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Form.Check style={{position: 'relative', top: '-35px', right: '-140px'}} type="switch"
                                            checked={clientSession.mute} onChange={handleChangeMute} id="mute-switch"
                                            label="Mute for therapist"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-0">
                            <Col xs="2">
                                <Form.Label>Mode</Form.Label>
                            </Col>
                            <Col xs="5">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-mode">{clientSession.mode}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {modesList}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs="4" className="mt-4">
                                <Button id="testTrigger" className="mr-3"
                                        onClick={handleTriggerTest}>{triggerTestLabel}</Button>
                                <Button id="runTrigger" className="mr-3"
                                        onClick={handleTriggerRun}>{triggerRunLabel}</Button>
                                <Button className="mr-3" onClick={handleReset}>Reset</Button>
                            </Col>
                            <Col xs="5">
                                <p>Counter: {counter}</p>
                                <p>Sets: {sets}</p>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>

                <AnimatePresence>
                    {showCardModal &&
                    <motion.div
                        initial={{x: 200}}
                        variants={cardModalVariants}
                        animate="show"
                        exit={{opacity: 0, transition: {duration: 1}}}
                        style={{position: "fixed", zIndex: 10, bottom: "80px", right: "40px"}}>
                        <Card className="" onClose={() => setShowCardModal(false)}>
                            <Card.Body>{cardModalBody}</Card.Body>
                        </Card>
                    </motion.div>
                    }
                </AnimatePresence>

                <div id="fullscreenToggle" style={{position: "fixed", bottom: "80px", right: "40px"}}>
                    {isFullScreen &&
                    <OverlayTrigger placement="top" delay={{show: 250, hide: 400}} onToggle={resetTooltipText}
                                    overlay={renderTooltipExitFullScreen}>
                        <FontAwesomeIcon className="ml-2 mr-2" size="2x" style={{cursor: "pointer"}} icon={faCompress}
                                         onClick={toggleFullScreen}/>
                    </OverlayTrigger>
                    }
                    {!isFullScreen &&
                    <OverlayTrigger placement="top" delay={{show: 250, hide: 400}} onToggle={resetTooltipText}
                                    overlay={renderTooltipOpenFullScreen}>
                        <FontAwesomeIcon className="ml-2 mr-2" size="2x" style={{cursor: "pointer"}} icon={faExpand}
                                         onClick={toggleFullScreen}/>
                    </OverlayTrigger>
                    }
                </div>

                <div style={{position: "fixed", bottom: "80px", left: "40px"}}>
                    {isConnected.client &&
                    <small className="text-muted"><FontAwesomeIcon className="mr-2 online" icon={faGlobe}/>Client is
                        online<br/></small>
                    }
                    {!isConnected.client &&
                    <small className="text-muted"><FontAwesomeIcon className="mr-2 offline" icon={faGlobe}/>Client is
                        offline<br/></small>
                    }
                    {isConnected.therapist &&
                    <small className="text-muted"><FontAwesomeIcon className="mr-2 online" icon={faGlobe}/>Therapist is
                        online<br/></small>
                    }
                    {!isConnected.therapist &&
                    <small className="text-muted"><FontAwesomeIcon className="mr-2 offline" icon={faGlobe}/>Therapist is
                        offline<br/></small>
                    }
                </div>
            </>
            }

            <Footer/>
        </div>
    )

}

export default TherapistSession;
