import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const HelpTopic = (props) => {

    const helpTopic = props.helpTopic;

    return (
        <>

            <Card className="mb-3" style={{ width: '100%' }} key={helpTopic._id}>
                <Accordion.Toggle as={Card.Header} eventKey={helpTopic._id}>
                    {helpTopic.header}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={helpTopic._id}>
                    <Card.Body dangerouslySetInnerHTML={{ __html: helpTopic.bodyText}}></Card.Body>
                </Accordion.Collapse>
            </Card>

        </>
    )
}
export default HelpTopic
