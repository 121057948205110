import React, {useState, useContext} from 'react';
import AuthService from '../../services/AuthService';
import Message from '../partials/Message';
import PageHeading from "../partials/PageHeading";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {AuthContext} from "../../context/AuthContext";
import {useHistory} from "react-router";

const debug = false;

const ChangePassword = (props) => {

    const {user} = useContext(AuthContext);
    const [passwords, setPasswords] = useState({password: "", passwordConfirm: ""});
    const [message, setMessage] = useState(null);

    const history = useHistory();

    const onChange = e => {
        setMessage(null); // Clear error message
        setPasswords({...passwords, [e.target.name]: e.target.value});
    }

    const onSubmit = e => {
        e.preventDefault();
        let validationErrors = [];
        if (passwords.password === '') validationErrors.push('Password is mandatory');
        // Verify if passwords are the same
        if (passwords.password !== passwords.passwordConfirm) validationErrors.push('Passwords don\'t match');
        if (validationErrors.length > 0) {
            setMessage( {msgBody: validationErrors.join('<br/>'), msgError: true});
            return;
        }

        AuthService.changePassword({...user, ...passwords}).then(data => {
            if (debug) console.log('data',data);
            const {message} = data;
            if (debug) console.log('message', message);
            setMessage(message);
            // Redirect user to therapist page if there is no error message (i.e. successful change password)
            if (!message.msgError) {
                setMessage({...message, ['msgError']: true}); // Set msgError to true because that style looks better
                // Redirect user to login page 2 seconds after receiving back the success message
                setTimeout(() => {
                    history.push('/therapist');
                }, 2000);

            }
        });
    }

    const [passwordType, setPasswordType] = useState('password');
    const [passwordConfirmType, setPasswordConfirmType] = useState('password');

    const togglePassword = (field) => {
        if (field === 'password') setPasswordType(passwordType === 'password' ? 'text' : 'password');
        if (field === 'passwordConfirm') setPasswordConfirmType(passwordConfirmType === 'password' ? 'text' : 'password');
    }

    return (
        <div className="container">
            <PageHeading title='EMDR Bilateral Stimulator' />
            <Form className="ml-5" onSubmit={onSubmit}>
                <h3 className="mb-3">Change password</h3>
                <input readOnly={true} type="text" name="email" value={user.email} autoComplete="username email" style={{display: "none"}}/>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="password">
                            <Form.Control name="password" type={passwordType} placeholder="Enter password"
                                          autoComplete="current-password" value={passwords.password || ""} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        {passwordType === 'password' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEye} onClick={(() => togglePassword("password"))}/>
                        }
                        {passwordType === 'text' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEyeSlash} onClick={(() => togglePassword("password"))}/>
                        }
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="passwordConfirm">
                            <Form.Control name="passwordConfirm" type={passwordConfirmType} placeholder="Confirm password"
                                          autoComplete="current-password" value={passwords.passwordConfirm || ""} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        {passwordConfirmType === 'password' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEye} onClick={(() => togglePassword("passwordConfirm"))}/>
                        }
                        {passwordConfirmType === 'text' &&
                        <FontAwesomeIcon className="text-secondary"
                                         style={{position: "absolute", top: "10px", left: "-40px", cursor: "pointer"}}
                                         icon={faEyeSlash} onClick={(() => togglePassword("passwordConfirm"))}/>
                        }
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Button className="btn btn-primary btn-block mb-3" type="submit">Change password</Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        {message ? <Message message={message}/> : null}
                    </Col>
                </Form.Row>
            </Form>
        </div>
    )
}

export default ChangePassword;
