import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthProvider from './context/AuthContext';
import {Provider as ReduxProvider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './store/reducers/rootReducer';
import './App.scss';

const debug = false;

let middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    if (debug) middleware = [...middleware, logger];
}

const reduxStore = createStore(rootReducer, applyMiddleware(...middleware));

ReactDOM.render(
      <ReduxProvider store={reduxStore}>
          <AuthProvider>
              <App />
          </AuthProvider>
      </ReduxProvider>,
  document.getElementById('root')
);
