import React, {useContext, useState} from 'react';
import AuthService from '../../services/AuthService';
import Message from '../partials/Message';
import PageHeading from "../partials/PageHeading";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {AuthContext} from "../../context/AuthContext";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";

const debug = false;

const EditProfile = (props) => {

    const {user, setUser} = useContext(AuthContext);

    const [message, setMessage] = useState(null);

    const history = useHistory();

    const onChange = e => {
        setMessage(null); // Clear error message
        setUser({...user, [e.target.name]: e.target.value});
    }

    const onSubmit = e => {
        e.preventDefault();
        let validationErrors = [];
        // Verify if all fields are filled in
        if (user.firstname === '') validationErrors.push('First name is mandatory');
        if (user.lastname === '') validationErrors.push('Last name is mandatory');
        AuthService.update(user).then(data => {
            if (debug) console.log('data',data);
            const {message} = data;
            if (debug) console.log('message', message);
            setMessage(message);
            // Redirect user to therapist page if there is no error message (i.e. profile updated)
            if (!message.msgError) {
                history.push('/therapist');
            }
        });
    }

    const handleChangePassword = () => {
        history.push('/therapist/changePassword');
    }

    return (
        <div className="container">
            <PageHeading title='EMDR Bilateral Stimulator' />
            <Form className="ml-5" onSubmit={onSubmit}>
                <h3 className="mb-3">Edit profile</h3>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="firstname">
                            <Form.Control name="firstname" type="text" placeholder="Enter first name" autoComplete="given-name" value={user.firstname} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Form.Group controlId="lastname">
                            <Form.Control name="lastname" type="text" placeholder="Enter last name" autoComplete="family-name" value={user.lastname} onChange={onChange}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Button className="btn btn-primary btn-block mb-3" type="submit">Update profile</Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        <Link to="#" onClick={handleChangePassword}>Change password</Link>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="11">
                        {message ? <Message message={message}/> : null}
                    </Col>
                </Form.Row>
            </Form>
        </div>
    )
}

export default EditProfile;
