import React, {useContext, useEffect, useState} from 'react';
import Message from '../partials/Message';
import PageHeading from "../partials/PageHeading";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {AuthContext} from "../../context/AuthContext";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {checkIfValidClientSession, createClientSession, deleteClientSession, fetchTherapistClientSessions} from "../../store/actions/clientSessionActions";
import Footer from "../partials/Footer";
import AuthService from "../../services/AuthService";
import Feedback from "../partials/Feedback";

const debug = false;

const CreateSession = (props) => {

    const {user, isAuthenticated} = useContext(AuthContext);
    if (debug) console.log('user',user);

    const dispatch = useDispatch();

    useEffect(() => {
        if (debug) console.log(`initState CreateSession (${user.email})`);
        if (user.email !== '') {
            dispatch(fetchTherapistClientSessions(user._id));
        }
    }, [user]);

    const [sessionName, setSessionName] = useState("");
    const [message, setMessage] = useState(null);

    const therapistClientSessions = useSelector((state) => state.clientSessions.therapistClientSessions);
    if (debug) console.log('therapistClientSessions', therapistClientSessions);

    const createNewClientSession = async (clientSession) => {
        // Trigger dispatch to create new client session action
        if (debug) console.log('create new client session - before dispatch');
        await dispatch(createClientSession(clientSession));
        if (debug) console.log('create new client session - after dispatch');
    }

    const unauthenticatedContent = () => {
       return (
            <div className="container">
                <PageHeading title='EMDR Bilateral Stimulator'/>
                <p className="text-center">An account allows sessions to be saved so that clients can use the same link every session.</p>

                <div className="container h-50 d-flex align-items-center justify-content-center">
                    <ButtonToolbar>
                        <Link to="/therapist/login">
                            <Button variant="primary" className="mx-5">Login</Button>
                        </Link>
                        <Link to="/therapist/register">
                            <Button variant="primary" className="mx-5">Register</Button>
                        </Link>
                    </ButtonToolbar>
                </div>
            </div>
        )
    }

    const onChange = e => {
        setMessage(null); // Clear error message
        let input = e.target.value.replace(" ","-");    // Replace spaces with -
        input = input.replace(/[^a-zA-Z0-9- ]/g, "");   // Prevent any other characters than a-zA-Z0-9 and -
        input = input.substring(0, 40);                 // Limit input to 40 characters
        setSessionName(input);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        // Prevent the form from being submitted if no email or password is entered
        // Validate
        if (!/^[a-zA-Z0-9-]{3,}$/.test(sessionName)) {
            setMessage({ msgBody: "Please enter a valid session name", msgError: true });
            return;
        }
        // Check if session already exist (if for same therapist then continue that session)
        // There should be maximum one match
        const clientSessionExists = await checkIfValidClientSession(sessionName);
        if (clientSessionExists) {
            // Check if session is created by this therapist
            const existingClientSessions = therapistClientSessions.filter(cSession => cSession.name === sessionName);
            if (existingClientSessions.length && existingClientSessions[0].user_id === user._id) {
                setMessage({ msgBody: "You already have a client session under that name. Please continue that session or create one with another name.", msgError: true });
            }
            // If client session not created by this therapist show error
            else {
                setMessage({ msgBody: "Another therapist already has a client session under that name. Please choose a different name.", msgError: true });
            }
            // client session already exists, so return without creating a new one
            return;
        }

        // Create session
        if (debug) console.log('verified ok');
        await createNewClientSession({name: sessionName, user_id: user._id});
        props.history.push(`/therapist/`+sessionName);
    }

    const [verificationEmailResend, setVerificationEmailResend] = useState(false);

    const handleResendVerificationEmail = (e) => {
        e.preventDefault();
        // Resend verification email
        AuthService.resendVerifyEmailHash(user.email)
            .then(result => {
                if (debug) console.log('Email verification email resend', result);
            })
            .catch(err => {
                console.error('Something went wrong', err);
            });

        // set verificationEmailResend to true
        setVerificationEmailResend(true);
    }

    const handleDelete = (e, clientSession) => {
        if (debug) console.log(`delete clientSession: ${clientSession._id}`);
        // TODO: ask for confirmation
        dispatch(deleteClientSession(clientSession._id));
        // TODO: Show modal to confirm deletion
    }

    const authenticatedContent = () => {
        return (
            <div className="container">
                <PageHeading title='Create a new session'/>
                {!user.emailVerified && !verificationEmailResend &&
                <>
                    <p>
                        Your email address is not yet verified.<br/>
                        Didn't receive the welcome email? Click the button to send a new email with a verification link.
                    </p>
                    <Button className="mb-3 btn-secondary" onClick={handleResendVerificationEmail}>Resend verification
                        email</Button>
                </>
                }
                {!user.emailVerified && verificationEmailResend &&
                <p className="mb-3">Please check your inbox for an email with a link to verify your email address.</p>
                }
                <Form className="mt-3" onSubmit={onSubmit}>
                    <Form.Row>
                        <Col xs={5}>
                            <Form.Group controlId="name">
                                <Form.Label className="sr-only">Session ID:</Form.Label>
                                <Form.Control type="text"
                                              name="name"
                                              onChange={onChange}
                                              value={sessionName}
                                              className="form-control mb-3"
                                              placeholder="example-name"
                                />
                                <Form.Text className="text-muted">
                                    <ul>
                                        <li>Minimum 3 characters</li>
                                        <li>Maximum 40 characters</li>
                                        <li>Only regular letters and numbers</li>
                                    </ul>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col xs="2">
                            <Button className="btn btn-primary mb-3" type="submit">Create session</Button>
                        </Col>
                    </Form.Row>
                </Form>
                {message ? <Message message={message}/> : null}
                {therapistClientSessions.length > 0 &&
                <>
                    <PageHeading title='Continue an existing session'/>
                    <ul>
                        {therapistClientSessions.map((clientSession, idx) => (
                            <li key={idx}>
                                <FontAwesomeIcon className="ml-1"
                                                 style={{position: "relative", left: "340", cursor: "pointer"}}
                                                 icon={faTrashAlt} onClick={((e) => handleDelete(e, clientSession))}/>
                                <Link to={{pathname: `/therapist/${clientSession.name}`}}>
                                    {clientSession.name}
                                </Link>
                            </li>

                        ))}
                    </ul>
                </>
                }
                <Feedback/>
            </div>
        )
    }

    return (
        <>
            {!isAuthenticated ? unauthenticatedContent() : authenticatedContent()}
            <Footer/>
        </>
    )

}

export default CreateSession;
