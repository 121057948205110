import axios from 'axios';

const debug = false;

const url = '/api/user';

export default {
    register: user => {
        return axios.post(`${url}/register`, user)
            .then(res => {
                if (debug) console.log('register res',res);
                return res.data;
            });
    },
    update: user => {
        return axios.post(`${url}/update`, user)
            .then(res => {
                if (debug) console.log('update res',res);
                return res.data;
            });
    },
    resendVerifyEmailHash: (email) => {
        return axios.get(`${url}/resendVerifyEmailHash/${email}`)
            .then(res => {
                if (debug) console.log('resendVerifyEmailHash res',res.data);
                return res.data;
            })
            .catch(err => {
                console.error('resendVerifyEmailHash err', err);
                return {message: {msgBody: "Error has occurred", msgError: true, msgDetail: err.toString()}};
            });

    },
    verifyEmailHash: (email, verifyEmailHash) => {
        return axios.get(`${url}/verifyEmailHash/${email}/${verifyEmailHash}`)
            .then(res => {
                if (debug) console.log('verifyEmailHash res',res.data);
                return res.data;
            })
            .catch(err => {
                console.error('verifyEmailHash err', err);
                return {message: {msgBody: "Error has occurred", msgError: true, msgDetail: err.toString()}};
            });

    },
    login: user => {
        return axios.post(`${url}/login`, user)
            .then(res => {
                if (debug) console.log('login res',res);
                if (res.status !== 401)
                    // Successful login. Return user
                    return res.data;
            else
            {
                if (debug) console.log('auth failed');
                return {isAuthenticated: false, user: {email: '', firstname: '', lastname: '', role: ''}, sessionExpires: null,  message: { msgBody: "Incorrect credentials, please try again", msgError: true }};
            }

        })
    },
    forgotPassword: (email) => {
        return axios.get(`${url}/forgotPassword/${email}`)
            .then(res => {
                if (debug) console.log('forgotPassword res',res);
                return res.data;
            })
            .catch(err => {
                console.error('forgotPassword err', err);
            });
    },
    resetPassword: (user) => {
        return axios.post(`${url}/resetPassword`, user)
            .then(res => {
                if (debug) console.log('resetPassword res',res);
                return res.data;
            });
    },
    verifyPasswordResetHash: (passwordResetHash) => {
        return axios.get(`${url}/verifyPasswordResetHash/${passwordResetHash}`)
            .then(res => {
                if (debug) console.log('verifyPasswordResetHash res',res.data);
                return res.data;
            })
            .catch(err => {
                console.error('verifyPasswordResetHash err', err);
                return {message: {msgBody: "Error has occurred", msgError: true, msgDetail: err.toString()}};
            });

    },
    changePassword: (user) => {
        console.log('changePassword user', user);
        return axios.post(`${url}/changePassword`, user)
            .then(res => {
                if (debug) console.log('changePassword res',res);
                return res.data;
            });
    },
    logout: () => {
        return axios.get(`${url}/logout`)
            .then(res => {
                if (debug) console.log('logout res',res);
                return res.data;
            })
            .catch(err => {
                console.error('logout err', err);
            });
    },
    isAuthenticated: () => {
        return axios.get(`${url}/authenticated`)
            .then(res => {
                if (debug) console.log('isAuthenticated res',res);
                if (res.status !== 401) {
                    if (debug) console.log('Status not 401');
                    return res.data;
                }
                else {
                    if (debug) console.log('Status 401 - Not authenticated');
                    return {isAuthenticated: false, user: {firstname: "", lastname: "", email: "", role: ""}};
                }
            })
            .catch(err => {
                if (debug) console.log('Status 401 (err) - Not authenticated', err);
                return {isAuthenticated: false, user: {firstname: "", lastname: "", email: "", role: ""}};
            });
    },
    list: () => {
        return axios.get(url+'/list');
    },
    // TODO: ADD view, update and delete user routes

}
