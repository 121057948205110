import axios from 'axios';

const debug = false;

const url = '/api/card';

export const fetchHelpTopics = () => {
    if (debug) console.log('fetchHelpTopics api call')
    return axios.get(`${url}/list`);
};

export const createHelpTopic = (newHelpTopic) => {
    if (debug) console.log('createHelpTopic api call', newHelpTopic);
    return axios.post(url, newHelpTopic);
}

export const updateHelpTopic = (id, updatedHelpTopic) => {
    if (debug) console.log('updateHelpTopic api call', id, updatedHelpTopic);
    return axios.put(`${url}/${id}`, updatedHelpTopic);
}

export const deleteHelpTopic = (id) => {
    if (debug) console.log('deleteHelpTopic api call');
    return axios.delete(`${url}/${id}`);
}

export const getHelpTopic = (id) => axios.get(`${url}/${id}`);
