import React from 'react';
import { Link } from "react-router-dom";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Button from 'react-bootstrap/Button';
import Footer from "./partials/Footer";

const Home = () => {
    return (
        <div className="vh-100">
            <div className="h-25 w-100 d-inline-block text-center">
                <h1 className="display-3 mt-5">EMDR Bilateral Stimulator</h1>
                <h3 className="text-secondary">Online tool for bilateral dual-attention stimulation</h3>
            </div>
            <p className="container bg-secondary p-3">Bilateral stimulation is stimuli (visual, auditory or tactile) which
                occur in a rhythmic left-right pattern. For example, visual bilateral stimulation could involve
                watching a hand or moving light alternating from left to right and back again. Auditory bilateral
                stimulation could involve listening to tones that alternate between the left and right sides of the
                head. Bilateral stimulation is a treatment element of EMDR.<br/>
                <br/>
                This tool can be used to give visual and auditory bilateral stimulation. This tool is free to use for
                both client and therapist. Therapists are required to register. This allows sessions to be saved so that
                clients can use the same link every session.
            </p>
            <div className="container h-25 d-flex align-items-center justify-content-center">
                <ButtonToolbar>
                    <Link to="/therapist"><Button variant="primary" size="lg" className="mx-5">Therapist</Button></Link>
                    <Link to="/client"><Button variant="primary" size="lg" className="mx-5">Client</Button></Link>
                </ButtonToolbar>
            </div>
            <Footer/>
        </div>
    )
}
export default Home;
