import io from 'socket.io-client';
let socket;

const debug = false;

const webSocketUrl = process.env.REACT_APP_WS_URL || 'http://localhost:3000';
if (debug) console.log('webSocketUrl', webSocketUrl);

export const initiateSocket = (sessionName, clientType) => {

    socket = io(webSocketUrl);
    if (debug) console.log(`Connecting socket...`);
    if (socket && sessionName)
        socket.emit('join', { sessionName, clientType });
}

export const disconnectSocket = () => {
    if (debug) console.log('Disconnecting socket...');
    if(socket)
        socket.disconnect();
}

export const subscribeToClientSession = (cb) => {
    if (!socket)
        return true;
    socket.on('action', action => {
        if (debug) console.log('Websocket event received!');
        return cb(null, action);
    });
}

export const isConnected = (cb) => {
    return cb(socket.connected);
}

export const sendAction = (action, actionData) => {
    if (socket)
        socket.emit('action', { action, actionData });
}
