import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from "./hoc/ProtectedRoute";
import Home from './components/Home';
import Help from './components/help/Help';
import Client from './components/client/Client';
import ClientSession from './components/client/ClientSession';
import Register from './components/therapist/Register';
import EditProfile from './components/therapist/EditProfile';
import VerifyEmail from './components/therapist/VerifyEmail';
import Login from './components/therapist/Login';
import ChangePassword from './components/therapist/ChangePassword';
import ResetPassword from './components/therapist/ResetPassword';
import CreateSession from './components/therapist/CreateSession';
import TherapistSession from './components/therapist/TherapistSession';
import Admin from "./components/admin/Admin";
import Footer from "./components/partials/Footer";
import HelpTopicForm from "./components/admin/HelpTopicForm";
import NotFound from './components/NotFound';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/help" component={Help}/>
                <Route exact path="/client" component={Client}/>
                <Route exact path="/client/:name" component={ClientSession}/>
                <Route exact path="/therapist/register" component={Register}/>
                <ProtectedRoute exact path="/therapist/editProfile" component={EditProfile}/>
                <ProtectedRoute exact path="/therapist/changePassword" component={ChangePassword}/>
                <Route exact path="/therapist/verifyEmail/:email/:emailVerificationHash" component={VerifyEmail}/>
                <Route exact path="/therapist/login" component={Login}/>
                <Route exact path="/therapist/resetPassword/:passwordResetHash" component={ResetPassword}/>
                <Route exact path="/therapist" component={CreateSession}/>
                <ProtectedRoute exact path="/therapist/:name" component={TherapistSession}/>
                <ProtectedRoute role="admin" exact path="/admin" component={Admin}/>
                <ProtectedRoute role="admin" exact path="/admin/helpTopic" component={HelpTopicForm}/>
                <ProtectedRoute role="admin" exact path="/admin/helpTopic/:id" component={HelpTopicForm}/>
                <Route component={NotFound} />
            </Switch>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
