import React, {useContext, useState} from "react";
import {motion, useAnimation} from 'framer-motion'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import * as api from "../../api/feedbackApi";

function Feedback() {

    const {user} = useContext(AuthContext);

    const [feedback, setFeedback] = useState('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    const openFeedback = () => {
        linkControls.start("hide").then();
        formControls.start("show").then();
    }

    const closeFeedback = () => {
        formControls.start("hide").then();
        linkControls.start("show").then();
        setFeedback('');
    }

    const onSubmit = async e => {
        e.preventDefault();
        const {data} = await api.sendFeedback({_id: user._id, feedback: feedback});
        setFeedbackSubmitted(true);
        closeFeedback();
        setTimeout(() => {
            linkControls.start("hide")
                .then(() => {
                    setFeedbackSubmitted(false);
                    linkControls.start("show");
                })
        }, 3000)
    }

    const onCancel = () => {
        closeFeedback();
    }

    const onChange = e => {
        setFeedback(e.target.value);
    }

    const linkControls = useAnimation();

    const linkVariants = {
        show: {
            opacity: 1,
            transition: {duration: 0.5}
        },
        hide: {
            opacity: 0,
            transition: {duration: 0.5}
        }
    }

    const formControls = useAnimation();

    const formVariants = {
        show: {
            scale: 1,
            opacity: 1,
            transition: {duration: 0.5}
        },
        hide: {
            scale: 0.0001,
            opacity: 0,
            transition: {duration: 0.5}
        }
    }

    const enableFeedback = process.env.REACT_APP_ENABLE_FEEDBACK.toLowerCase() === 'true';

    return (
        <>
            {enableFeedback &&
            <>
                <p className="mt-5">Have a request for a new feature? Anything else you want to share?</p>
                <motion.div variants={linkVariants} initial={{opacity: 1}} animate={linkControls}>
                    {feedbackSubmitted ?
                        <>Thank you for your feedback.</>
                        :
                        <Link to="#" onClick={openFeedback}>Leave feedback</Link>
                    }
                </motion.div>
                <motion.div style={{position: "relative", top: '-2em'}} variants={formVariants}
                            initial={{scale: 0.0001, opacity: 0}} animate={formControls}>
                    <Form className="mt-3" onSubmit={onSubmit}>
                        <Form.Group controlId="feedback">
                            <Form.Control as="textarea" name="feedback" rows={3} value={feedback} onChange={onChange}/>
                        </Form.Group>
                        <Button className="btn btn-primary mb-3" type="submit" onClick={onSubmit}>Leave
                            feedback</Button>
                        <Button className="btn btn-secondary ml-3 mb-3" type="button" onClick={onCancel}>Cancel</Button>
                    </Form>
                </motion.div>
            </>
            }
        </>
    )

}

export default Feedback;
