import React, {createContext, useState, useEffect} from 'react';
import AuthService from '../services/AuthService';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router";

const debug = false;

export const AuthContext = createContext(); // Creates both a Provider and a Consumer

export default ({children}) => {
    const [user, setUser] = useState({email: '', firstname: '', lastname: '', role: '', emailVerified: false});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [sessionExpires, setSessionExpires] = useState();

    let logoutWarningTimer;

    const timeLeftWarningPeriod = 30; // Show the logoutWarning for 30 seconds
    const [showLogoutWarning, setShowLogoutWarning] = useState(false);
    const [timeLeft, setTimeLeft] = useState(timeLeftWarningPeriod);

    // This useEffect only runs once
    useEffect(() => {
        AuthService.isAuthenticated().then(data => {
            setUser(data.user);
            if (debug) console.log('AuthContext user', user);
            setIsAuthenticated(data.isAuthenticated);
            if (debug) console.log('setting sessionExpires', data.sessionExpires);
            setSessionExpires(data.sessionExpires);
        });

    }, []);

    // This use effect runs when sessionExpires is updated
    useEffect(() => {
        if (debug) console.log('useEffect set Timers, auth:', isAuthenticated);
        if (isAuthenticated) {
            if (debug) console.log('sessionExpires', sessionExpires);
            // Set logout warning timer
            if (debug) console.log('set logout warning timer');
            const logoutWarningIn = new Date(sessionExpires) - Date.now() - 60000; // Logout warning one minute before session expires
            logoutWarningTimer = setTimeout(logoutWarning, logoutWarningIn);
            if (debug) console.log('logoutWarningTimer', logoutWarningTimer);
        }

        // Unmount
        return () => {
            // Clear timer on unmount
            if (debug) console.log('Clear timer');
            if (debug) console.log('logoutWarningTimer', logoutWarningTimer, typeof logoutWarningTimer);
            clearTimeout(logoutWarningTimer);
            if (debug) console.log('Cleared timers');
        }

    }, [sessionExpires]);

    // This use effect runs every second
    useEffect(() => {
        let timer;
        // Only run this counter when showLogoutWarning is displayed
        if (showLogoutWarning) {
            timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            // Logout user if no time left
            if (debug) console.log('Check if time left is up' , timeLeft);
            if (timeLeft < 1)
                logout();
        }
        // Clear timer on unmount
        return () => {
            clearTimeout(timer);
        }
    });

    function logoutWarning() {
        if (debug) console.log('Show logout warning');
        setShowLogoutWarning(true);
        if (debug) console.log('showLogoutWarning', showLogoutWarning);
    }

    function handleLogoutWarningConfirm() {
        setShowLogoutWarning(false);
        // Reset timeLeft timer
        setTimeLeft(timeLeftWarningPeriod);
        AuthService.isAuthenticated().then(data => {
            setUser(data.user);
            setIsAuthenticated(data.isAuthenticated);
            setSessionExpires(data.sessionExpires);
        });
    }

    const history = useHistory();

    function logout() {
        setShowLogoutWarning(false);
        // Reset timeLeft timer
        setTimeLeft(timeLeftWarningPeriod);
        AuthService.logout().then(data => {
            if (debug) console.log('logout data', data);
            if (data.success) {
                if (debug) console.log('success');
                setUser(data.user);
                setIsAuthenticated(false);
                // Redirect to homepage
                if (typeof history !== 'undefined') history.push(`/`);
                //history.push(`/`);
            }
        });
    }

    return (
        <div>
            <AuthContext.Provider value={{user, setUser, isAuthenticated, setIsAuthenticated, sessionExpires, setSessionExpires}}> {/* Available as global state */}
                {children}
            </AuthContext.Provider>

            {/* Logout warning confirm dialog */}
            <Modal show={showLogoutWarning} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Session expiration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Your session will expire in {timeLeft} seconds.<br/>
                        Click 'Stay logged in' to continue your session.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={logout}>Log out</Button>
                    <Button variant="primary" onClick={handleLogoutWarningConfirm}>Stay logged in</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
