import axios from 'axios';

const debug = true;

const url = '/api/feedback';

export const sendFeedback = (feedback) => {
    if (debug) console.log('sendFeedback api call', feedback);
    return axios.post(`${url}`, feedback);
};
